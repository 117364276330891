import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import {
  UserCircleIcon,
  ChatBubbleBottomCenterTextIcon,
  HeartIcon,
  HomeIcon,
  ArrowRightOnRectangleIcon,
  LifebuoyIcon,
  UserIcon,
  KeyIcon,
} from "@heroicons/react/24/outline";
import { IoMagnet, IoMail, IoMailSharp } from "react-icons/io5";
import { changePassword } from "services/services/User/userService";
import Avatar from "shared/Avatar/Avatar";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import logoutImg from "../../images/logout.gif";
import logoutImg_2 from "../../images/ezgif.com-resize.gif";

import { Link, useNavigate } from "react-router-dom";
import { getSessionUsingSessionStorage } from "services/services/common/session";

const solutions = [
  {
    name: "Account",
    href: "/author",
    icon: UserCircleIcon,
  },
  {
    name: "Messages",
    href: "##",
    icon: ChatBubbleBottomCenterTextIcon,
  },
  {
    name: "Wishlists",
    href: "/account-savelists",
    icon: HeartIcon,
  },
  {
    name: "Booking",
    href: "##",
    icon: HomeIcon,
  },
];

const solutionsFoot = [
  {
    name: "Change Password",
    href: "/changePassword",
    icon: KeyIcon,
  },
  {
    name: "Profile",
    href: "/profile",
    icon: UserCircleIcon,
  },  
  // {
  //   name: "Logout",
  //   href: "##",
  //   icon: ArrowRightOnRectangleIcon,
  // },
];

export default function AvatarDropdown(parUserDetails : any) {
  
const [isOpenLogoutModal, setisOpenLogoutModal] = useState(false);
const closeLogoutModal = () => setisOpenLogoutModal(false);
const openLogoutModal = () => setisOpenLogoutModal(true);
const [errorMessage, setErrorMessage] = useState(undefined);
// const [userDetails, setUserDetails] = useState([] | null);

const [userDetails, setUserDetails] = useState(parUserDetails);

const navigate = useNavigate();
   
useEffect(() => {

  const promise = getSessionUsingSessionStorage();
     /**check authentication */
     promise
     .then(function (value: any) {
       return value;
     })
     .then((value: any) => {

       setUserDetails(value);
       return value;
     });



}, [])

  /**confirm Logout */
  const logoutHandller = () => {
    try {
                 
      localStorage.removeItem("userDetails");
      navigate("/login");
        setErrorMessage(undefined);   
    } 
    catch (error: any) {
        setErrorMessage(error);
    } 
    finally {    
      setisOpenLogoutModal(false);
    }
  }


const renderLogoutModal = () => {
  return (
    <div>
      <Transition appear show={isOpenLogoutModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeLogoutModal}
        >
          <div className="min-h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-flex flex-col w-300 max-w-4xl align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-300">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Logout?
                  </Dialog.Title>
                  <span className="absolute left-3 top-3">
                    <ButtonClose onClick={closeLogoutModal} />
                  </span>
                </div>

                <div className="flex-grow overflow-y-auto">
                  <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                    <div className="py-5">
                       <div className="mt-6 relative  flex text-center justify-between ">

                         {/* <div className="lg:w-1/3 " >   
                         </div> */}
                         <div className="lg:w-3/3 lg:h-3/3">
                             <img src={logoutImg_2} alt="logout" className="logoutImage"  />
                             {/* height="40%" width="40%"  */}
                         </div>
                         {/* <div className="lg:w-1/3 " >
                         </div> */}
                      </div> 
                      <div className="mt-6 relative text-center">
                          Are your sure want to logout?
                      </div>
                    </div>                                
                  </div>
                </div>

                <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 items-right justify-between">
                  <ButtonThird
                    onClick={closeLogoutModal}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    Cancel
                  </ButtonThird>

                  <ButtonPrimary
                    onClick={logoutHandller}
                    sizeClass="px-4 py-2 sm:px-5"
                    className="left-2"
                  >
                    Yes, logout
                  </ButtonPrimary>
                </div>

                {/* <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-right justify-between">
                  <ButtonThird
                    onClick={closeLogoutModal}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    Clear
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={closeLogoutModal}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    Apply
                  </ButtonPrimary>
                </div> */}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};


  return (
    <div className="AvatarDropdown">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              {/* <Popover.Panel className="absolute z-10 w-screen max-w-[260px] px-4 mt-4 -right-10 sm:right-0 sm:px-0"> */}
              <Popover.Panel className="absolute z-10 w-screen max-w-[300px] px-4 mt-4 -right-10 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    {/* {solutions.map((item, index) => (
                      <Link
                        key={index}
                        to={item.href}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </Link>
                    ))} */}

                  {
                      <Link
                        key={"1"}
                        to={"#"}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                           <UserIcon aria-hidden="true" className="w-6 h-6" /> 
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">  {userDetails?.fullName}</p>
                        </div>
                      </Link>                     
                    
                    }

                    {
                      <Link
                        key={"2"}
                        to={"#"}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <IoMail aria-hidden="true" className="w-6 h-6" /> 
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">  {userDetails?.email}</p>
                        </div>
                      </Link> 
                    }


                  </div>
                  <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 p-7">
                    {solutionsFoot.map((item, index) => (
                      <a
                        key={index}
                        href={item.href}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <item.icon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">{item.name}</p>
                        </div>
                      </a>
                    ))}

                  {
                      <a
                         onClick={openLogoutModal}
                        key={"3"}
                        href={"#"}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                          <ArrowRightOnRectangleIcon aria-hidden="true" className="w-6 h-6" />
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium ">Logout</p>
                        </div>
                      </a>
                    } 

                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>

      {renderLogoutModal()}
    </div>
  );
}
