import React from "react";
interface Props extends React.PropsWithChildren<any> { }
const IconAndactivities: React.FC<Props> = ({ icon, title }) => {
  return (
    <div className="d-flex flex-column align-items-center m-1">
      <div>{icon}</div>
      <p>{title}</p>
    </div>
  );
};

export default IconAndactivities;
