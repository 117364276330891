import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import facebookSvg from "images/Facebook.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SignInImage from "images/SignIn_1.png";
import NcImage from "shared/NcImage/NcImage";

//Forms
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

//Common Service
import { forgotPassword } from "../../../services/services/User/userService";


export interface ForgotPasswordProps {
  className?: string;
}


const ForgotPassword: FC<ForgotPasswordProps> = ({ className = "" }) => {

  const [value, setValue] = useState({ username: "", password: "" });
  const [passwordShow, setPasswordShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { username, password } = value;
  const navigate = useNavigate();

  const forgotPassowrd = async (values: any) => {
    setIsLoading(true);
    try {
      const user = await forgotPassword({
        Email: values.username,
        Application_type: "client",
      });

      if (user.status === "SUCCESS") {
        navigate("/forgotpassword-success");
        setErrorMessage("");
      }
    } catch (error: any) {
      setErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: username,
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please enter your Email").email("Email format is not valid"),
    }),
    onSubmit: (values) => {
      forgotPassowrd(values);
    },
  });
    

  return (

    
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Forgot Password || TravelNinjaz</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-2 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {/* Login */}
        </h2>
       

        <div className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`} data-nc-id="SectionSubscribe2" >
          <div className="flex-grow">
            <NcImage src={SignInImage} />
          </div> 
          <div className="flex-shrink-0 mb-10 lg:mb-0 lg:ml-10 lg:w-2/5 sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
            
              <h2 className="font-semibold text-4xl">Forgot Password</h2>
                        
              {/* FORM */}
            
              <form
                 className="grid grid-cols-1 gap-6"
                  action="#"
                  onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                  }}
                >

                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                   Email
                  </span>
                  <Input
                    id="username"
                    type="email"
                    placeholder=""
                    className="mt-1"
                    required={true}
                    onChange={validation.handleChange}
                    value={validation.values.username}
                    onBlur={validation.handleBlur}
                    autoComplete={"off"} 
                  />

                 {validation.touched.username &&
                      validation.errors.username ? (                       
                      <p className="validationError" style={{ color: "red" }}>
                        {validation.errors.username.toLocaleString()}
                      </p>
                 ) : null}                  

                </label>               
                {errorMessage !== undefined ? (
                        <p className="validationError" style={{ color: "red" }}>
                        {errorMessage}
                    </p>
                        ) : (
                          ""
                        )}
                <ButtonPrimary type="submit" loading={isLoading}>Send Reset Link</ButtonPrimary>
              </form>
                    <div>                              
          
                 

          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Wait, I remember my password... {` `}
            <Link to="/login" className="underline">Click here</Link>
          </span>

            </div>

    
    </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
