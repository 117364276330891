import React, { FC, ReactNode, useEffect, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";
import HeadingDestinationFilter from "./HeadingDestinationFilter";
import { getPackageQuotationList } from "../../services/services/globalmaster/packageServices";
import PackageCard from "components/PackageCard/PackageCard";
import { errornotify } from "components/common/Notification";

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

//
export interface BestSellingPackage {
    stayListings?: StayDataType[];
    gridClass?: string;
    heading?: ReactNode;
    subHeading?: ReactNode;
    headingIsCenter?: boolean;
    tabs?: string[];
    destinationList: any;
}

const BestSellingPackage: FC<BestSellingPackage> = ({
    stayListings = DEMO_DATA,
    gridClass = "",
    heading = "Best Selling Packages",
    subHeading = "Popular packages Travelninjaz recommends for you",
    headingIsCenter,
    tabs = ["New York", "Tokyo", "Paris", "London"],
    destinationList,
}) => {
    const renderCard = (packageDetails: any) => {
        return <PackageCard key={packageDetails?.package_id} data={packageDetails} />;
    };
    const [bestPackageList, setBestPackageList] = useState<any>(undefined)
    const [activeTabDestination, setActiveTabDestination] = useState(destinationList?.length > 0 && destinationList[0]?.destination_id)
    const [userName, setUserName] = useState(
        undefined || "manisha@aahvan.com"
    );
    /**This function for get packages */
    const getData = async (activeTabDestination: any) => {
        try {
            if (activeTabDestination) {
                let params = new URLSearchParams();
                params.append("UserId", userName);
                params.append("destination_id", activeTabDestination)
                const pkgResponse = await getPackageQuotationList(params)
                if (pkgResponse?.data?.status === "SUCCESS") {
                    let allPackages = pkgResponse?.data?.data;
                    const bestPackages = allPackages?.filter((packages: any) => {
                        return packages?.is_active && packages?.is_best_selling
                    }
                    );
                    setBestPackageList(bestPackages)
                } else {
                    throw pkgResponse?.message;
                }

            }
        } catch (error) {
            errornotify(error)
        }

    }


    /**filter tab handller */
    const activeTabHandller = (item: any) => {
        setActiveTabDestination(item?.destination_id)
        getData(item?.destination_id);
    }

    useEffect(() => {
        if (destinationList) {
            setActiveTabDestination(destinationList[0]?.destination_id)
            getData(destinationList[0]?.destination_id)
        }
    }, [destinationList])

    return (
        <div className="nc-SectionGridFeaturePlaces relative">
            <HeadingDestinationFilter
                tabActive={activeTabDestination}
                subHeading={subHeading}
                tabs={destinationList}
                heading={heading}
                onClickTab={activeTabHandller}
            />
            <div
                className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
            >
                {bestPackageList?.map((stay: any) => renderCard(stay))}
            </div>
            {/* <div className="flex mt-16 justify-center items-center">
                <ButtonPrimary loading>Show me more</ButtonPrimary>
            </div> */}
        </div>
    );
};

export default BestSellingPackage;
