import NcInputNumber from 'components/NcInputNumber/NcInputNumber';
import RequiredMessage from 'components/common/RequiredMessage';
import FormItem from 'containers/PageAddListing1/FormItem'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { currencyIcon } from 'services/services/common/const';
import ButtonSecondary from 'shared/Button/ButtonSecondary'
import Checkbox from 'shared/Checkbox/Checkbox';
import Select from 'shared/Select/Select'
import { selectCityHotelSiteseeing, selectNumberofHotels } from 'store/action';
import convertNumbThousand from 'utils/convertNumbThousand';
import HotelMealPlanNights from './HotelMealPlanNights';
export interface PackageDetailsCityProps {
    item?: any;
    pkgCityHOtelmeal?: any;
    onChangeEventHandller?: (e: any, packageCityId: any) => void;
    index?: any;
    isRequiredField?: any;
    isFavorite?: boolean;
}
const InternationalPackageCIty: React.FC<PackageDetailsCityProps> = ({ isRequiredField, isFavorite = false, item, pkgCityHOtelmeal, index }) => {

    // const [numberOfHotels, setNumberOfHotels] = useState<any>(1);
    const [userName, setUserName] = useState(
        undefined || "manisha@aahvan.com"
    );
    const [byDefaultDetails, setByDefaultDetails] = useState({
        is_active: true,
        row_created_date: new Date(),
        row_created_by: userName,
        row_altered_date: new Date(),
        row_altered_by: userName,
    });
    const {
        is_active,
        row_created_by,
        row_created_date,
        row_altered_by,
        row_altered_date,
    } = byDefaultDetails;
    const numberOfHotels = useSelector(
        (state: any) => state.selectOptionFilterReducer.numberOfHotels
    )

    const [packageHotelmealNightsList, setPackageHotelMealNightsList] = useState(isFavorite ? pkgCityHOtelmeal[0]?.packageCityHotelList : [])
    const dispatch = useDispatch()
    /**This is for according to number of hotels */
    useEffect(() => {
        if (pkgCityHOtelmeal) {
            dispatch(selectNumberofHotels(packageHotelmealNightsList?.length))
        }
    }, [pkgCityHOtelmeal])

    /**This is for city site seeing**/
    const [pkgcityhotelsiteSeeingList, setPkgCityHotelSiteSeeingList] = useState({
        Number_of_hotels: numberOfHotels,
        city_id: item.city_id,
        city_name: item.city_name,
        package_city_id: item.package_city_id,
        packageCityHotelList: [],
        siteSeeingList: [] as any,
        Is_active: is_active,
        Row_created_by: row_created_by,
        Row_created_date: row_created_date,
        Row_altered_by: row_altered_by,
        Row_altered_date: row_altered_date,
    });

    /**This is for handller site seeing */
    const onChangeHandllerSiteSeeing = (
        e: any,
        key: any,
        packageCityId: any,
        package_quotation_siteseeing_id: any
    ) => {
        const findIndex = pkgcityhotelsiteSeeingList.siteSeeingList
            ?.map(function (item: any) {
                return item.key;
            })
            .indexOf(key);
        if (findIndex > -1) {
            pkgcityhotelsiteSeeingList.siteSeeingList?.splice(findIndex, 1);
        } else {
            pkgcityhotelsiteSeeingList.siteSeeingList?.push({
                Package_quotation_siteseeing_id: package_quotation_siteseeing_id,
                City_site_seeing_id: parseInt(e.target.value),
                Site: e.target.name,
                key: key,
                Is_active: is_active,
                Row_created_by: row_created_by,
                Row_created_date: row_created_date,
                Row_altered_by: row_altered_by,
                Row_altered_date: row_altered_date,
            });
        }
        setPkgCityHotelSiteSeeingList({ ...pkgcityhotelsiteSeeingList, siteSeeingList: pkgcityhotelsiteSeeingList.siteSeeingList })
        // dispatch(selectCityHotelSiteseeing(pkgcityhotelsiteSeeingList))
    };
    /**This useeffect se pkgCitySiteseeingList */
    useEffect(() => {
        setPkgCityHotelSiteSeeingList({ ...pkgcityhotelsiteSeeingList, packageCityHotelList: packageHotelmealNightsList, })
    }, [packageHotelmealNightsList])

    /**This useEffect send citydetails */
    useEffect(() => {
        dispatch(selectCityHotelSiteseeing(pkgcityhotelsiteSeeingList))
    }, [pkgcityhotelsiteSeeingList])


    const filterCityHotelMealPlans = pkgCityHOtelmeal?.filter(
        (itemCity: any, index: any) => {
            return itemCity.package_city_id === item.package_city_id;
        }
    );
    const [mealPlanList, setMealPlanList] = useState([]);
    const [hotelList, setHotelList] = useState(
        filterCityHotelMealPlans[0]?.packageCityHotelList
    );
    /**This is for selected hotel id and meal plan code */
    const [selectedHotelId, setSelectedHotelId] =
        useState(filterCityHotelMealPlans[0]?.packageCityHotelList[0]?.hotel
            ?.selectedHotelMealPlan?.hotel_id);
    const [selectedMealPlan, setSelectedMealPlan] = useState(
        filterCityHotelMealPlans[0]?.packageCityHotelList[0]?.hotel
            ?.selectedHotelMealPlan?.meal_plan_code
    );
    /**This is for onChangeHandller */
    const onChangeNumberofHotelHandller = (e: any) => {
        if (!isFavorite) {
            let tempHotelMealPlanList: any = [];
            [...Array(parseInt(e.target.value))]?.map((itemNumber: any, index: any) => {
                tempHotelMealPlanList?.push({
                    key: index,
                    hotel: {
                        hotel_id: selectedHotelId,
                        selectedHotelMealPlan: {
                            meal_plan_code: selectedMealPlan,
                            Is_active: is_active,
                            Row_created_by: row_created_by,
                            Row_created_date: row_created_date,
                            Row_altered_by: row_altered_by,
                            Row_altered_date: row_altered_date,
                        },
                        Is_active: is_active,
                        Row_created_by: row_created_by,
                        Row_created_date: row_created_date,
                        Row_altered_by: row_altered_by,
                        Row_altered_date: row_altered_date,
                    },
                    nights: 1,
                    Is_active: is_active,
                    Row_created_by: row_created_by,
                    Row_created_date: row_created_date,
                    Row_altered_by: row_altered_by,
                    Row_altered_date: row_altered_date,
                })
            })
            setPackageHotelMealNightsList(tempHotelMealPlanList)
        }
        dispatch(selectNumberofHotels(parseInt(e.target.value)))

    }

    console.log("packageHotelmealNightsList", packageHotelmealNightsList)
    /**onChagne event handller */
    const onChangeEventHandller = (e: any, key: any, nights: any) => {
        packageHotelmealNightsList?.map((item: any) => {
            if (item?.key === key) {
                if (e?.target?.name === "hotel_id") {
                    item.hotel.hotel_id = parseInt(e.target.value);
                }
                else if (e?.target?.name === "meal_plan_code") {
                    item.hotel.selectedHotelMealPlan.meal_plan_code = e.target.value;
                }
                else if (nights) {
                    debugger;
                    item.nights = nights;
                }
                setPkgCityHotelSiteSeeingList({ ...pkgcityhotelsiteSeeingList, packageCityHotelList: packageHotelmealNightsList })
                //  e?.target?.name === "hotel_id" ? parseInt(e.target.value) : item.hotel.hotel_id;
                // item?.hotel?.selectedHotelMealPlan?.meal_plan_code=e?.target?.name === "meal_plan_code" ? e.target.value : item?.hotel?.selectedHotelMealPlan?.meal_plan_code;
                // return {
                //     ...item,
                //     hotel: {
                //         hotel_id: e?.target?.name === "hotel_id" ? parseInt(e.target.value) : item.hotel.hotel_id,
                //         selectedHotelMealPlan: {
                //             meal_plan_code: e?.target?.name === "meal_plan_code" ? e.target.value : item?.hotel?.selectedHotelMealPlan?.meal_plan_code,
                //         },
                //     },
                //     nights: nights ? nights : item.nights
                // };
            }

            // return item;
        }
        )
    }
    const packageCityId = filterCityHotelMealPlans[0]?.package_city_id;

    let nights = 0;
    item?.packageCityHotelList?.map((itemCity: any, index: any) => {
        nights += itemCity?.nights
    })
    return (
        <div className="listingSection__wrap">
            <div>
                <h2 className="text-2xl font-semibold">City {index + 1} - {item?.city_name} </h2>
            </div>
            <div className=" grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 ">

                {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"> */}
                <FormItem
                    label="No of Hotels"
                // isRequired={true}
                >
                    <Select id={`selectHotel_${item?.city_id}_${index}`}
                        defaultValue={numberOfHotels}
                        name="numberOfHotels"
                        value={numberOfHotels}
                        onChange={onChangeNumberofHotelHandller}>
                        <option value="">Select no of hotel</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>

                    </Select>
                    {!selectedHotelId && isRequiredField ? <RequiredMessage message="Hotel is required" /> : ""}
                </FormItem>
                <FormItem className='ml-8'
                    label="Nights" desc={nights}
                ></FormItem>
            </div>
            {
                packageHotelmealNightsList?.map((itemHotel: any, i: any) => {
                    return <HotelMealPlanNights activeKey={itemHotel?.key} onChangeEventHandller={onChangeEventHandller} index={i} hotelList={hotelList} selectedHotelId={selectedHotelId} selectedMealPlan={selectedMealPlan} />
                    // return <div>
                    //     <div className=" grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 mb-2">  <FormItem
                    //         label="Hotel"
                    //         isRequired={true}
                    //     >
                    //         <Select id={`selectHotel_${item?.city_id}_${index}`}
                    //             defaultValue={selectedHotelId}
                    //             name="hotel_id"
                    //             onChange={(e) => onChangeHandller(e, packageCityId)}>
                    //             <option value={undefined} selected>
                    //                 Select hotel
                    //             </option>
                    //             {hotelList?.map((itemHotel: any, index: any) => {
                    //                 return (
                    //                     <option
                    //                         value={itemHotel?.hotel?.hotel_id}
                    //                         key={index}
                    //                         selected={itemHotel?.hotel?.hotel_id === selectedHotelId}
                    //                     >

                    //                         {itemHotel?.hotel?.hotel_name}
                    //                     </option>
                    //                 );
                    //             })}
                    //         </Select>
                    //         {!selectedHotelId && isRequiredField ? <RequiredMessage message="Hotel is required" /> : ""}
                    //     </FormItem>

                    //         <FormItem className='ml-8 mb-2'
                    //             label="Nights"
                    //         >
                    //             <NcInputNumber max={nights} defaultValue={1} />

                    //         </FormItem>
                    //     </div>
                    //     <FormItem
                    //         label="Meal Plans"
                    //         isRequired={true}
                    //     >
                    //         <Select id="selectMealPlans" name="meal_plan_code"
                    //             defaultValue={selectedMealPlan}
                    //             onChange={(e) => onChangeHandller(e, packageCityId)}>
                    //             <option value={undefined} selected>
                    //                 Select Meal plans
                    //             </option>
                    //             {mealPlanList?.map((item: any, index: any) => {
                    //                 return (
                    //                     <option
                    //                         value={item.meal_plan_code}
                    //                         key={index}
                    //                         selected={item.meal_plan_code === selectedMealPlan}
                    //                     >
                    //                         {item?.meal_plan_code}
                    //                         (Adult :
                    //                         {currencyIcon} {convertNumbThousand(parseInt(item?.adult_price))}
                    //                         , child/ex adult :
                    //                         {currencyIcon} {convertNumbThousand(parseInt(item?.child_price))}
                    //                         , child (wihout bed):
                    //                         {currencyIcon} {convertNumbThousand(parseInt(
                    //                             item?.child_price_without_bed ? item?.child_price_without_bed : 0
                    //                         ))}
                    //                         )
                    //                     </option>
                    //                 );
                    //             })}
                    //         </Select>
                    //         {!selectedMealPlan && isRequiredField ? <RequiredMessage message="Meal plans is required" /> : ""}
                    //     </FormItem>
                    // </div>;
                })
            }

            <div>
                <label className="text-lg font-semibold" htmlFor="">
                    City Site Seeings
                </label>
                <div className="mt-6 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-5">
                    {item?.siteSeeingList?.map((itemSite: any, i: any) => {
                        return <Checkbox label={`${itemSite?.site} (${currencyIcon} ${convertNumbThousand(parseInt(itemSite?.rate))})`} name={itemSite?.site}
                            onChange={(e) =>
                                onChangeHandllerSiteSeeing(
                                    e,
                                    itemSite?.key,
                                    packageCityId,
                                    itemSite?.package_quotation_siteseeing_id
                                )}
                            id={`${item.city_id}_${index}_${itemSite?.site}${itemSite?.key}`}
                            value={itemSite?.city_site_seeing_id}
                            defaultChecked={itemSite?.is_selected}
                        />

                    })}


                </div>
            </div>

        </div>
    )
}

export default InternationalPackageCIty