import { ReactSession } from "react-client-session";

/************************ SESSION UEING KEY - METHODS STARTS *****************************/

// export function getSession(Key) {
//   return readSessionData(Key);
// }
export const getSession = async (key: any) => {
  var sessionValue = "";

  sessionValue = ReactSession.get(key);

  if (
    sessionValue === undefined ||
    sessionValue === null ||
    sessionValue === "" ||
    sessionValue === "undefined"
  ) {
    return {};
  } else {
    return sessionValue;
  }
};

export function setSession(Key: any, Value: any) {
  return storeSessionData(Key, Value);
}

// async function readSessionData(Key) {
//   var sessionValue = "";

//   sessionValue = ReactSession.get(Key);

//   if (
//     sessionValue === undefined ||
//     sessionValue === null ||
//     sessionValue === "" ||
//     sessionValue === "undefined"
//   ) {
//     return {};
//   } else {
//     return sessionValue;
//   }
// }

async function storeSessionData(Key: any, Value: any) {
  // ReactSession.setStoreType("sessionStorage");
  // ReactSession.set(Key, Value);
  localStorage.setItem(Key, JSON.stringify(Value));
}

/**get session data without using react session */
export const getSessionUsingSessionStorage = async () => {
  let sessionValue = "";
  // sessionValue = sessionStorage.getItem("__react_session__");

  const storedObjectString: any = localStorage.getItem("userDetails");
  sessionValue = JSON.parse(storedObjectString);
  if (
    sessionValue === undefined ||
    sessionValue === null ||
    sessionValue === "" ||
    sessionValue === "undefined"
  ) {
    return {};
  } else {
    // let sessionData = JSON.parse(sessionValue)?.userDetails;
    return sessionValue;
  }
};
/************************METHODS ENDS *****************************/

// export const getSession = () => {
//   return {};
// };

// const SESSION_NAME = "MFSSusSession";

// async function storeDataForWeb(credentials) {

// }

// async function readDataForWeb() {
//   var credentials = "";

//   if (
//     credentials === undefined ||
//     credentials === null ||
//     credentials === "" ||
//     credentials === "undefined"
//   ) {
//     return {};
//   } else {
//     return JSON.parse(credentials);
//   }
// }

// export function getSession() {
//     return readDataForWeb();
//   }

// export function setSession(credentials) {
//       return storeDataForWeb(credentials);
// }
