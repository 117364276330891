import { CITYOPTION_FILTER, CITY_HOTEL_SITESEEINGLIST, DESTINATION_LIST, ISBESTSELLING_PACKAGE, ISFAMILY_PACKAGE, ISHONEYMOON_PACKAGE, MAXIMUM_PRICE, MINIMUM_PRICE, NUMBER_OF_HOTELS, NUMBER_OF_NIGHTS, PACKAGE_DETAILS_PARTICULAR, PKGREVIEW_RATE } from "./actionType";

export const selectNumberOfnights = (data: any) => {
    return {
        type: NUMBER_OF_NIGHTS,
        payload: data,
    };
};
export const minimumPriceRange = (data: any) => {
    return {
        type: MINIMUM_PRICE,
        payload: data,
    };
}
export const maximumPriceRange = (data: any) => {
    return {
        type: MAXIMUM_PRICE,
        payload: data,
    };
}
export const bestsellingPackage = (data: any) => {
    return {
        type: ISBESTSELLING_PACKAGE,
        payload: data,
    };
}
export const ishoneymoonPackage = (data: any) => {
    return {
        type: ISHONEYMOON_PACKAGE,
        payload: data,
    };
}
export const isfamilyPackage = (data: any) => {
    return {
        type: ISFAMILY_PACKAGE,
        payload: data,
    };
}
export const cityOptionsFilter = (data: any) => {
    return {
        type: CITYOPTION_FILTER,
        payload: data,
    };
}
export const pkgReviewRate = (data: any) => {
    return {
        type: PKGREVIEW_RATE,
        payload: data,
    }
}
/**This is for selection number of hotels */
export const selectNumberofHotels = (data: any) => {
    return {
        type: NUMBER_OF_HOTELS,
        payload: data,
    }
}
/**This is for set international city hotel siteseeing details */
export const selectCityHotelSiteseeing = (data: any) => {
    return {
        type: CITY_HOTEL_SITESEEINGLIST,
        payload: data,
    }
}
/****This is for destination list */
export const destinationListAction = (data: any) => {
    return {
        type: DESTINATION_LIST,
        payload: data,
    }
}
/**This is for packageDeatails particular */
export const packageDetailsByPackageId = (data: any) => {
    return {
        type: PACKAGE_DETAILS_PARTICULAR,
        payload: data,
    }
}