import { toast } from "react-toastify";

/**This is for success notify */
export const successnotify = (message: any) =>
  toast(message, {
    position: "bottom-center",
    hideProgressBar: true,
    style: { backgroundColor: "#28a745", color: "#ffff" }
    // className: "bg-success text-white",
  });
/**This is for error notify */
export const errornotify = (message: any) =>
  toast(message, {
    position: "bottom-center",
    hideProgressBar: true,
    // className: "bg-danger text-white",
    style: { backgroundColor: "#dc3545", color: "#ffff" }
    // autoClose: false,
  });
