import  { FC } from "react";
import { Helmet } from "react-helmet";

//Images
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";

export interface UserErrorScreenProps {
  className: string,
  status: string,
  statusMessage: string,
  descriptionMessage: string,
  submitButtonText: string,
  image: string, 
  supportDescription: string,  
  onClickHandller?: () => void;
}

const UserErrorScreen: FC<UserErrorScreenProps> = ({
  className = "",
  status = "",
  statusMessage ="",
  descriptionMessage = "",
  submitButtonText = "",
  image = "",
  supportDescription = "", 
  onClickHandller,     
    }) => {

  return (

    
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Error || TravelNinjaz</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-2 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {/* Signup */}
        </h2>
        <div className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`} data-nc-id="SectionSubscribe2" >
                <div className="flex-grow">
                  <NcImage src={image} />
                </div>
                <div className="flex-shrink-0 mb-10 lg:mb-0 lg:ml-10 lg:w-3/5 sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
                  
                    <h2 className="font-semibold text-4xl">  {statusMessage} </h2>     
                   
                    <div className="flex-shrink-0">
                        {descriptionMessage}          
                    </div>  
                    {/* <h4 className={status ? "" : "text-danger"}>
                      {statusMessage}
                    </h4> */}                 
                   
                    <div className="flex-shrink-0">
                        {supportDescription}
                    </div>  

                    <div className="flex-shrink-0">

                     {submitButtonText !== undefined && 
                     submitButtonText != ""
                     ? (
                      <ButtonPrimary onClick={onClickHandller}>{submitButtonText}</ButtonPrimary>     
                      ) : (
                        ""
                      )}                                            
                    </div>  
                </div>  
                    
            </div>    
    </div>
    
    </div>
  );
};

export default UserErrorScreen;
