import React, { FC, useEffect, useState } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridHasMap from "../ListingStayPage/SectionGridHasMap";
import { Helmet } from "react-helmet";
import { getPackageQuotationList, getPackageUserFavoritePriceList } from "../../services/services/globalmaster/packageServices";
import { useParams } from "react-router-dom";
import { GetCityList } from "services/services/globalmaster/cityServices";
import { debug } from "console";
import { errornotify } from "components/common/Notification";
import { applicationName } from "services/services/common/const";
import SectionGridFeatureProperty from "containers/PageHome/SectionGridFeatureProperty";
import SectionHero3 from "components/SectionHero/SectionDestinationImage";
import SectionHero5_full from "components/SectionHero/SectionHero5_full";

import { useSelector } from "react-redux";

export interface ListingStayMapPageProps {
    className?: string;
}

const FilterPackage: FC<ListingStayMapPageProps> = ({
    className = "",
}) => {
    const [packages, setPackages] = useState(undefined);
    const [destinationName, setDestinationName] = useState(undefined);
    const [totalPackages, setTotalPackages] = useState(0);
    const [userName, setUserName] = useState(
        undefined || "manisha@aahvan.com"
    );
    const [cityList, setCityList] = useState(undefined)
    const { destinationId } = useParams<any | string>();
    const destinationDetails = useSelector(
        (state: any) => state.selectOptionFilterReducer.destinationList
    )
    const [destinationFilter, setDestinationFilter] = useState<any>(undefined)

    /**This function for get packages */
    const getData = async () => {
        try {
            if (destinationId) {
                let params = new URLSearchParams();
                params.append("UserId", userName);
                if (destinationId !== "favoritePackages") {
                    params.append("destination_id", destinationId)
                }


                const particularDestination = destinationDetails?.filter((itemDestination: any, index: any) => {
                    return itemDestination.destination_id === parseInt(destinationId)
                })
                if (particularDestination?.length > 0) {
                    setDestinationFilter(particularDestination[0])
                }
                let pkgResponse = []
                if (destinationId === "favoritePackages") {
                    pkgResponse = await getPackageUserFavoritePriceList(params);
                           }
                else {
                    pkgResponse = await getPackageQuotationList(params)
                }

                if (pkgResponse?.data?.status === "SUCCESS") {
                    const ctResponse = await GetCityList();
                    if (ctResponse?.data?.status === "SUCCESS") {
                        const allCityList = ctResponse?.data?.data;
                        const activeCities = allCityList?.filter((cities: any) => {
                            return cities?.is_active && destinationId === "favoritePackages" ? true : cities?.destination_id === parseInt(destinationId);
                        })
                        setCityList(activeCities)
                    }
                    else {
                        throw ctResponse?.message;
                    }
                    let allPackages = pkgResponse?.data?.data;
                    const activePackages = allPackages?.filter((packages: any) => {
                        return packages?.is_active
                    }
                    );
                    if (activePackages?.length > 0) {
                        setDestinationName(activePackages[0]?.destination_name)
                        setTotalPackages(activePackages?.length)
                        setPackages(activePackages)
                    }
                }
                else {
                    throw pkgResponse?.message;
                }
            }
        } catch (error) {
            errornotify(error)
        }
    }
    useEffect(() => {
        getData()
    }, [destinationId])

    return (
        <div
            className={`nc-ListingStayMapPage relative ${className}`}
            data-nc-id="ListingStayMapPage"
        >
            <Helmet>
                <title>{applicationName} || Filter packages</title>
            </Helmet>
            <BgGlassmorphism />
      
            <SectionHero5_full destinationImage={destinationId !== "favoritePackages" ? destinationFilter?.destination_image : ""}  ></SectionHero5_full>
            {/* SECTION HERO */}
            {/* <div className="container px-1 sm:px-4 mb-24 ">
                <SectionHero3 className="" destiNationName={destinationId === "favoritePackages" ? "Favorite packages" : destinationFilter?.destination_name} destinationDescription={destinationFilter?.destination_description} destinationImage={destinationFilter?.destination_image} />
            </div> */}
            {/* <div className="container pt-10 pb-24 lg:pt-16 lg:pb-28">
                <SectionHeroArchivePage packages={packages} currentPage="Stays" destinationName={destinationName} packageCount={totalPackages} currentTab="Stays" />
            </div> */}

            <div className="relative py-24 px-28">
                {/* <BackgroundSection /> */}
                {/* <SectionGridFeatureProperty /> */}
                <SectionGridHasMap citeies={cityList} destinationName={destinationName} packageCount={totalPackages} packages={packages} />
            </div>
            {/* SECTION */}
            {/* <div className="container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none">
                <SectionGridHasMap citeies={cityList} destinationName={destinationName} packageCount={totalPackages} packages={packages} />
            </div> */}

            <div className="container overflow-hidden">
                {/* SECTION 1 */}
                <div className="relative py-24">
                    <BackgroundSection />
                    <SectionSliderNewCategories
                        heading="Explore by types of stays"
                        subHeading="Explore houses based on 10 types of stays"
                        categoryCardType="card5"
                        itemPerRow={5}
                        sliderStyle="style2"
                        uniqueClassName="ListingStayMapPage"
                    />
                </div>


                {/* SECTION */}
                <SectionSubscribe2 className="py-24 lg:py-28" />

                {/* SECTION */}
                <div className="relative py-16 mb-24 lg:mb-28">
                    <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
                    <SectionGridAuthorBox />
                </div>
            </div>
        </div>
    );
};

export default FilterPackage;
