import React, { FC, useState } from "react";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import StayCardH from "components/StayCardH/StayCardH";
import GoogleMapReact from "google-map-react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import PackageFilterCard from "components/PackageFilterCard/PackageFilterCard";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";

const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);

export interface SectionGridHasMapProps {
  destinationName?: string | undefined;
  packageCount?: number | undefined;
  packages?: any | undefined;
  citeies?: any | undefined;
  gridClass?: string;
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = ({ destinationName,
  packageCount,
  packages, citeies, gridClass = "" }) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const { destinationId } = useParams<any | string>();
  /**get filter number of nights */
  const filterNumberOFNights = useSelector(
    (state: any) => state.selectOptionFilterReducer.numberOfNights
  );
  /**minimum price  from filter */
  const minimuPriceFromfilter = useSelector(
    (state: any) => state.selectOptionFilterReducer.minimumPrice
  );
  /**maximum price  from filter*/
  const maximumPriceFromFilter = useSelector(
    (state: any) => state.selectOptionFilterReducer.maximumPrice
  )
  /**this is for best selling filter */
  const bestSellingFilter = useSelector(
    (state: any) => state.selectOptionFilterReducer.isBestSellingFilter
  )
  /**this is for family package filter */
  const familyPackageFilter = useSelector(
    (state: any) => state.selectOptionFilterReducer.isFamilyPackageFilter
  )
  /**This is for honeymoon package filter */
  const honeyMoonPackageFilter = useSelector(
    (state: any) => state.selectOptionFilterReducer.isHoneymoonPackageFilter
  )
  /**This is for cityOption filter */
  const cityFilterValue = useSelector(
    (state: any) => state.selectOptionFilterReducer.cityOptionsValue
  )
  return (
    <>
      <div className="nc-SectionGridFeatureProperty relative">
        <Heading2 heading={destinationId === "favoritePackages" ? `Favorite packages` : `Packages of ${destinationName}`} destinationName={destinationName} packageCount={packageCount} packages={packages} />
       
        <div className="pt-10 pb-10 items-center justify-center mb-8 lg:mb-11 sticky top-20 w-full relative mt-8 flex rounded-3xl shadow-xl dark:shadow-2xl bg-neutral-100 dark:bg-neutral-800 " style={{ zIndex: "5" }}>
        {/* <BackgroundSection /> */}
          <TabFilters cities={citeies} destinationName={destinationName} packageCount={packageCount} packages={packages} />
        </div>
        <div
          className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 ${gridClass}`}
        >
          {packages?.filter((packageFilter: any) => {
            let totalNightsum = 0;
            let packageCity: any = [];
            packageFilter?.packageCityList?.map((itemCity: any, index: any) => {
              itemCity?.packageCityHotelList?.map((itemHotel: any, index: any) => {
                totalNightsum += itemHotel?.nights;
              })
              packageCity.push(itemCity.city_id);
            });
            console.log(minimuPriceFromfilter)
            return (true && (filterNumberOFNights > 0
              ? totalNightsum === filterNumberOFNights
              : true) &&
              (maximumPriceFromFilter > 0 ? (minimuPriceFromfilter <= packageFilter?.package_price && packageFilter?.package_price <= maximumPriceFromFilter) : true)
              && (familyPackageFilter ? packageFilter?.is_family_package : true) && (bestSellingFilter ? packageFilter?.is_best_selling : true) && (honeyMoonPackageFilter ? packageFilter?.is_honeymoon_package : true)
              && (cityFilterValue.length > 0
                ? cityFilterValue.every((elem: any) =>
                  packageCity.includes(elem)
                )
                : true)
            );
          })?.map((item: any) => (
            <div
              key={item?.package_id}
              onMouseEnter={() => setCurrentHoverID((_) => item?.package_id)}
              onMouseLeave={() => setCurrentHoverID((_) => -1)}
            >
              <PackageFilterCard data={item} />

            </div>
          ))}
        </div>
      </div>
      {/* <div className="relative flex min-h-screen">
      
        <div className="min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 ">
          <Heading2 heading={`Packages of ${destinationName}`} destinationName={destinationName} packageCount={packageCount} packages={packages} />
          <div className="mb-8 lg:mb-11">
            <TabFilters cities={citeies} destinationName={destinationName} packageCount={packageCount} packages={packages} />
          </div>
          <div className="grid grid-cols-1 gap-8">
            {packages?.filter((packageFilter: any) => {
              let totalNightsum = 0;
              let packageCity: any = [];
              packageFilter?.packageCityList?.map((itemCity: any, index: any) => {
                itemCity?.packageCityHotelList?.map((itemHotel: any, index: any) => {
                  totalNightsum += itemHotel?.nights;
                })
                packageCity.push(itemCity.city_id);
              });
              console.log(minimuPriceFromfilter)
              return (true && (filterNumberOFNights > 0
                ? totalNightsum === filterNumberOFNights
                : true) &&
                (maximumPriceFromFilter > 0 ? (minimuPriceFromfilter <= packageFilter?.package_price && packageFilter?.package_price <= maximumPriceFromFilter) : true)
                && (familyPackageFilter ? packageFilter?.is_family_package : true) && (bestSellingFilter ? packageFilter?.is_best_selling : true) && (honeyMoonPackageFilter ? packageFilter?.is_honeymoon_package : true)
                && (cityFilterValue.length > 0
                  ? cityFilterValue.every((elem: any) =>
                    packageCity.includes(elem)
                  )
                  : true)
              );
            })?.map((item: any) => (
              <div
                key={item?.package_id}
                onMouseEnter={() => setCurrentHoverID((_) => item?.package_id)}
                onMouseLeave={() => setCurrentHoverID((_) => -1)}
              >
                <PackageFilterCard data={item} />
              
              </div>
            ))}
          </div>
         
        </div>
      </div> */}
    </>
  );
};

export default SectionGridHasMap;
