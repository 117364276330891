import { url } from "../common/const";
import { get, post } from "../common/https";
/**This service for get package list*/
export const GetPackageList = () => {
  const getdata = get(`${url}/api/Package/list`);
  return getdata;
};

/**This service for save userPackage*/
export const SavePackageUser = (data: any) =>
  post(`${url}/api/PackageQuotation/save`, data);

/**This service for get package check price */
export const getPackageQuotationList = (params: any) => {
  const getData = get(`${url}/api/PackageQuotation/list?` + params);
  return getData;
};
/**This service for get package user favorite check price list */
export const getPackageUserFavoritePriceList = (params?: any) => {
  const getData = get(`${url}/api/PackageQuotationUserFavorite/list?` + params);
  return getData;
};
/***This service for get package quotation details with id */
export const getPackageQuotationById = (params: any) => {
  const getData = get(
    `${url}/api/PackageQuotationUserFavorite/PackageQuotationById?` + params
  );
  return getData;
};
/**calculate package price */
/**This service for save userPackage*/
export const calculatePackagePrice = (data: any) =>
  post(`${url}/api/PackageQuotation/calculatePrice`, data);

/**This service for update userFavorite */
export const updateUserFavorite = (data: any) =>
  post(`${url}/api/PackageQuotation/updateFavorite`, data);

/**This iservice for save/update margin and description */
export const updateMarginAndDescription = (data: any) =>
  post(`${url}/api/PackageQuotation/updateMarginDescription`, data);

/**This is service for get city hotel mealplan details */
export const getCityHotelMealPlans = (params: any) => {
  const getData = get(
    `${url}/api/package/PackageCityHotelMealPlanList?` + params
  );
  return getData;
};
/***save  to favorite package default without any changes */
export const savePackageFavoriteFromHeader = (data: any) =>
  post(`${url}/api/PackageQuotationUserFavorite/SavePackageQuotation_AddUserFavoriteFromPackageHeader`, data)
/***remove from favorite package default without any changes */
export const removePackageFavoriteFromHeader = (data: any) =>
  post(`${url}/api/PackageQuotationUserFavorite/SavePackageQuotation_RemoveUserFavoriteFromPackageHeader`, data)
