import React, { FC } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
        } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${type === "type1" ? "lg:pl-16" : "lg:pr-16"
          }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">
          Why TravelNinjaz?
        </span>
        <h2 className="font-semibold text-4xl mt-5">Why You Are Travel With TravelNinjaz </h2>

        <ul className="space-y-10 mt-16">
          <li className="space-y-4">
            <Badge name="Guide" />
            <span className="block text-xl font-semibold">
              500+ Our Worldwide Guide
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              The path to success can be a daunting one, especially if you’re starting a business from zero all by yourself.
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="green" name="Secure " />
            <span className="block text-xl font-semibold">
              100% Trusted Travel Agency
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Millions of people are searching for unique places to stay around
              the world
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="blue" name="Experience" />
            <span className="block text-xl font-semibold">
              5+ Year Of Travel Experience
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Travel quotes are there to express the feeling you experienced while traveling.
            </span>
          </li>
          <li className="space-y-4">
            <Badge color="green" name="happines" />
            <span className="block text-xl font-semibold">
              85% Of Our Traveller Happy
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              I haven’t been everywhere, but it’s on my list.
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
