import React, { useEffect, useState } from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import { GetDestinationList } from "services/services/globalmaster/destintionServices";
import { useDispatch } from "react-redux";
import { destinationListAction } from "store/action";
import { getSessionUsingSessionStorage } from "services/services/common/session";

function B2bNavigation() {
    const [destination, setDestination] = useState({
        domesticDestination: undefined,
        internationalDestination: undefined,
    })
    const { domesticDestination, internationalDestination } = destination;
    const dispatch = useDispatch()
    const getApi = async () => {
        try {
            const dtResposne = await GetDestinationList();
            if (dtResposne?.data?.status === "SUCCESS") {
                const destinationList = dtResposne?.data?.data;

                //debugger;
                let domesticDestination: any = [];
                let interNationalDestination: any = [];
                const activeDestinationList = destinationList?.filter((itemFilter: any, index: any) => {
                    return itemFilter?.is_active;
                });
                dispatch(destinationListAction(activeDestinationList))
                activeDestinationList?.map((item: any, index: any) => {
                    if (item?.destination_type_id === 1) {
                        domesticDestination?.push({
                            id: item?.destination_id,
                            href: `/filterPackage/${item?.destination_id}`,
                            name: item?.destination_name,
                        })
                    } else {
                        interNationalDestination?.push({
                            id: item?.destination_id,
                            href: `/filterPackage/${item?.destination_id}`,
                            name: item?.destination_name,
                        })
                    }
                })
                setDestination({ ...destination, domesticDestination: domesticDestination, internationalDestination: interNationalDestination })
            }
            else {
                throw dtResposne?.message
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getApi();
    }, [])

    const NAVIGATION_DEMO: any = [
        {
            id: 1,
            href: "/filterPackage/1",
            name: "Domestic",
            type: "dropdown",
            children: domesticDestination,
            // isNew: true,
        },
        {
            id: 2,
            href: "/filterPackage/2",
            name: "International",
            type: "dropdown",
            children: internationalDestination,
            //type: "megaMenu",
            //megaMenu: megaMenuDemo,
        },
        {
            id: 3,
            href: "/filterPackage/favoritePackages",
            name: <> <span className="flex rounded-full  hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill={"#ff4c4c"}
                    viewBox="0 0 24 24"
                    stroke={"#ff4c4c"}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                    />
                </svg>
                <span className="hidden sm:block ml-2.5">Favorite packages</span>
            </span>
            </>,
            // children: internationalDestination,
        },
    ];

    return (
        <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
            {NAVIGATION_DEMO.map((item: any) => (
                <NavigationItem key={item.id} menuItem={item} />
            ))}
        </ul>
    );
}


export default B2bNavigation;