import RequiredMessage from 'components/common/RequiredMessage';
import FormItem from 'containers/PageAddListing1/FormItem'
import React from 'react'
import { currencyIcon } from 'services/services/common/const';
import Select from 'shared/Select/Select'
import convertNumbThousand from 'utils/convertNumbThousand';
export interface PackageTransferAndLocationProps {
    transportRateList?: any;
    packageDetails?: any;
    pickupAndDropList?: any;
    onChangeHandller?: any;
    isRequiredField?: any,
    cabtransfer?: any,
    pickuplocation?: any,
    droplocation?: any,
}
const PackageTransferAndLocation: React.FC<PackageTransferAndLocationProps> = ({ cabtransfer, droplocation, pickuplocation, isRequiredField, transportRateList, packageDetails, pickupAndDropList, onChangeHandller }) => {
    const renderRadio = (
        name: string,
        id: string,
        label: string,
        defaultChecked?: boolean,
        value?: any,
        onChange?: () => void,

    ) => {
        return (
            <div className="flex items-center mt-2">
                <input
                    defaultChecked={defaultChecked}
                    id={id}
                    name={name}
                    value={value}
                    onChange={onChange}
                    type="radio"
                    className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
                />
                <label
                    htmlFor={id}
                    className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                >
                    {label}
                </label>
            </div>
        );
    };
    return (
        <div className='listingSection__wrap'>
            <div>
                {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"> */}
                <div>
                    <label className="text-lg font-semibold" htmlFor="">
                        Cab Transfer <span style={{ color: "red" }}>*</span>
                    </label>
                    {
                        transportRateList
                            ?.filter(
                                (
                                    itemTransportFilter: any,
                                    index: any,
                                ) => {
                                    return (
                                        itemTransportFilter?.transport_rate_id ===
                                        packageDetails?.transport_rate_id
                                    );
                                }
                            )[0]
                            ?.transportRateList?.map(
                                (itemTransport: any, index: any) => {
                                    return (
                                        <>
                                            {renderRadio("cabtransfer",
                                                `${itemTransport?.transport_id}`,
                                                `${itemTransport?.vehicle_name}  (${currencyIcon} ${convertNumbThousand(parseInt(itemTransport?.vehicle_price))})`,
                                                (itemTransport?.transport_id) ===
                                                packageDetails?.transport_id ||
                                                itemTransport?.transport_id ===
                                                packageDetails
                                                    ?.selectedTransport
                                                    ?.transport_id
                                                ,
                                                itemTransport?.transport_id,
                                                onChangeHandller

                                            )
                                            }

                                            {/* <input
                                                type="radio"
                                                name="cabtransfer"
                                                id={`${itemTransport?.transport_id}`}
                                                value={
                                                    itemTransport?.transport_id
                                                }
                                                onChange={
                                                    onChangeHandller
                                                }
                                                defaultChecked={
                                                    itemTransport?.transport_id ===
                                                    packageDetails?.transport_id ||
                                                    itemTransport?.transport_id ===
                                                    packageDetails
                                                        ?.selectedTransport
                                                        ?.transport_id
                                                }
                                            />
                                            <label
                                                htmlFor={`${itemTransport?.transport_id}`}
                                                className="checkBoxStyle"
                                            >
                                                {
                                                    itemTransport?.vehicle_name
                                                }{" "}
                                                (
                                                {parseInt(
                                                    itemTransport?.vehicle_price
                                                ).toLocaleString(
                                                    "en-IN",
                                                    {
                                                        maximumFractionDigits: 2,
                                                        style: "currency",
                                                        currency: "INR",
                                                    }
                                                )}
                                                )
                                               
                                            </label> */}
                                        </>
                                    );
                                }
                            )}

                    {!cabtransfer && isRequiredField ? <RequiredMessage message="Cab Transfer is required" /> : ""}
                </div>

                <div className='mt-6'>
                    <label className="text-lg font-semibold" htmlFor="">
                        Pickup location <span style={{ color: "red" }}>*</span>
                    </label>
                    {pickupAndDropList
                        ?.filter(
                            (itemPickupFilter: any, index: any) => {
                                return (
                                    itemPickupFilter?.destination_id ===
                                    packageDetails?.destination_id
                                );
                            }
                        )[0]
                        ?.pickupLocation?.map(
                            (itemPickup: any, index: any) => {
                                return (
                                    <>
                                        {renderRadio("pickuplocation",
                                            `pickup_location_${itemPickup?.pickup_location_id}`,
                                            itemPickup?.pickup_location_name,
                                            itemPickup?.pickup_location_id ===
                                            packageDetails?.pickup_location_id,
                                            itemPickup?.pickup_location_id,
                                            onChangeHandller

                                        )
                                        }
                                        {/* <input
                                            type="radio"
                                            name="pickuplocation"
                                            id={`pickup_location_${itemPickup?.pickup_location_id}`}
                                            value={
                                                itemPickup?.pickup_location_id
                                            }
                                            onChange={
                                                onChangeHandller
                                            }
                                            defaultChecked={
                                                itemPickup?.pickup_location_id ===
                                                packageDetails?.pickup_location_id
                                            }
                                        />
                                        <label
                                            htmlFor={`pickup_location_${itemPickup?.pickup_location_id}`}
                                            style={{
                                                fontWeight: "500",
                                            }}
                                            className="checkBoxStyle"
                                        >
                                            {
                                                itemPickup?.pickup_location_name
                                            }
                                        </label> */}
                                    </>
                                );
                            }
                        )}
                    {!pickuplocation && isRequiredField ? <RequiredMessage message="Pickup location is required" /> : ""}
                </div>
                <div className='mt-6'>
                    <label className="text-lg font-semibold" htmlFor="">
                        Drop location <span style={{ color: "red" }}>*</span>
                    </label>
                    {pickupAndDropList
                        ?.filter(
                            (itemDropFilter: any, index: any) => {
                                return (
                                    itemDropFilter?.destination_id ===
                                    packageDetails?.destination_id
                                );
                            }
                        )[0]
                        ?.dropLocation?.map(
                            (itemDrop: any, index: any) => {
                                return (
                                    <>
                                        {renderRadio("droplocation",
                                            `droplocation_${itemDrop?.drop_location_id}`,
                                            `${itemDrop?.drop_location_name}`,
                                            itemDrop?.drop_location_id ===
                                            packageDetails?.drop_location_id,
                                            itemDrop?.drop_location_id,
                                            onChangeHandller,
                                        )}
                                        {/* <input
                                            type="radio"
                                            name="droplocation"
                                            id={`droplocation_${itemDrop?.drop_location_id}`}
                                            value={
                                                itemDrop?.drop_location_id
                                            }
                                            onChange={
                                                onChangeHandller
                                            }
                                            defaultChecked={
                                                itemDrop?.drop_location_id ===
                                                packageDetails?.drop_location_id
                                            }
                                        />
                                        <label
                                            htmlFor={`droplocation_${itemDrop?.drop_location_id}`}
                                            style={{
                                                fontWeight: "500",
                                            }}
                                            className="checkBoxStyle"
                                        >
                                            {
                                                itemDrop?.drop_location_name
                                            }
                                        </label> */}
                                    </>
                                );
                            }
                        )}
                    {!droplocation && isRequiredField ? <RequiredMessage message="Drop location is required" /> : ""}
                </div>

            </div>
        </div>
    )
}

export default PackageTransferAndLocation