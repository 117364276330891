import React from "react";

// Manisha - Change 10 ---------------------
interface Props extends React.PropsWithChildren<any> { }
const PDFContent_packageCityHotels: React.FC<Props> = ({
  headingName,
  cityHotelList
}) => {

  return (
    <>
      <tr>
        <td width={"20%"} className="listStyleHeadingPdf">
          {headingName}
        </td>
        <td>
          {cityHotelList ? (

            <ul>
              {cityHotelList?.map((cityItem: any, cityIndex: any) => {
                return <li key={cityItem.city_id}> <b> {cityItem.city_name + ":"} </b>
                  {cityItem?.packageCityHotelList?.map((hotelItem: any, hotelIndex: any) => {
                    return <span key={hotelIndex}>{hotelItem?.hotel?.hotel_name + " - " + hotelItem?.nights + " - " + hotelItem?.hotel?.selectedHotelMealPlan?.meal_plan_code} </span>
                  })}
                </li>
              }
              )}
            </ul>
          ) : (
            ""
          )}
        </td>
      </tr>
    </>
  );
};

export default PDFContent_packageCityHotels;
