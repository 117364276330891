import  { FC } from "react";
import Logo from "shared/Logo/Logo";

export interface MainNav3Props {
  className?: string;
}

const MainNav3: FC<MainNav3Props> = ({ className = "" }) => {
  
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />         
        </div>        
      </div>
    </div>

  );
};

export default MainNav3;
