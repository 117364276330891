import { url } from "../common/const";
import { get, post } from "../common/https";

export const getPackageReviewsList = (params: any) => {
  const getdata = get(`${url}/api/PackageReviews/List?` + params);
  return getdata;
};
export const savePackageReviews = (data: any) =>
  post(`${url}/api/PackageReviews/Save`, data);

