import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

//Images
import SignUpImage from "images/SignIn_3.png";

//Success Screen
import SuccessScreen from "../../../components/User/UserSuccessScreen"


export interface PageSignUpSuccessProps {
  className?: string;
}

const PageSignUpSuccess: FC<PageSignUpSuccessProps> = ({ className = "" }) => {

const navigate = useNavigate();
const onClickHandller = () => {
  navigate("/login");
};
return (
  <>
    <SuccessScreen
      className=""
      onClickHandller={onClickHandller}
      status = {"SUCCESS"}
      statusMessage={"Registration Successfull !"}
      descriptionMessage={"Now you can sign in."}
      submitButtonText={"Sign in"}    
      image = {SignUpImage}    
      supportDescription = {"Note - Please complete your email verification before log in to the system."}
    />
  </>
);
 
};

export default PageSignUpSuccess;
