import React from "react";
import TableHeadingContent from "./TableHeadingContent";
import PDFContent_packageCityHotels from "./PDFContent_packageCityHotels";
import PDFContent_packageGuidelines from "./PDFContent_packageGuidelines";
import PDFContent_packageItinerary from "./PDFContent_packageItinerary";
interface Props extends React.PropsWithChildren<any> { }
const TablePdfContent: React.FC<Props> = ({
  numberOfNights,
  destinationName,
  travelDate,
  cityHotelList,
  inclusions,
  packageGuideLines,
  packageItineraryList
}) => {
  return (
    <>
      <table width={"100%"}>
        <thead>
          <tr>
            <th
              colSpan={2}
              //   style={{ textAlign: "center" }}
              className="tableHeadPdfContainer pt-3 pb-3"
            >
              {numberOfNights}N {destinationName} Package
            </th>
          </tr>
        </thead>
        <tbody>
          <PDFContent_packageCityHotels
            headingName={"Hotels"}
            cityHotelList={cityHotelList}
          />
          <TableHeadingContent
            headingName={"Travel Date"}
            contents={travelDate}
          />

          <TableHeadingContent
            headingName={"Includes"}
            listContents={inclusions}
            isInclude={true}
          />
          <TableHeadingContent
            headingName={"Excludes"}
            listContents={inclusions}
            isInclude={false}
          />


          <PDFContent_packageItinerary
            headingName={"Itinerary"}
            packageItineraryList={packageItineraryList}
          />

          <PDFContent_packageGuidelines
            headingName={"Guidelines"}
            packageGuideLines={packageGuideLines}
          />

        </tbody>
      </table>
    </>
  );
};

export default TablePdfContent;
