import  { FC, useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";

//Images
import SignInImage from "images/SignIn_1.png";
import NcImage from "shared/NcImage/NcImage";

//Forms
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

//Common Service
import { resetPassword } from "../../../services/services/User/userService";
import { applicationName } from "../../../services/services/common/const";

//Password input
import UserPasswordInput from "../../../components/User/UserPasswordInput"

export interface ResetPasswordProps {
  className?: string;
}


const ResetPassword: FC<ResetPasswordProps> = ({ className = "" }) => {

  const [searchParams] = useSearchParams();
  const token = searchParams.get("t");
  const email = searchParams.get("e");
  const type = searchParams.get("type");
  const [value, setValue] = useState({
    password: "",
    confirmpassword: "",
    username: "",
  });
  const { password, confirmpassword, username } = value;
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmpasswordShow, setConfirmPasswordShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (email) {
      setValue({ ...value, username: email });
    }
    if (type === "changepassword") {
      document.title = `Change Password: ${applicationName}`;
    } else {
      document.title = `Reset Password: ${applicationName}`;
    }
  }, [email, type]);

  /**resset password */
  const passwordReset = async (user: any) => {
    setIsLoading(true);
    try {
      const response = await resetPassword({
        Email: email,
        Token: token,
        Password: user.password,
        ConfirmPassword: user.confirmpassword,
      });
      if (response.status === "SUCCESS") {
        if (type === "changepassword") {
          navigate("/login");
        } else {
          navigate("/resetpassword-success");
        }

        setErrorMessage(undefined);
      }
    } catch (error: any) {
      setErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: username,
      password: password,
      confirmpassword: confirmpassword,
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("Password is required"),
      confirmpassword: Yup.string()
        .oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password are not matched"
        )
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values) => {
      await passwordReset(values);
    },
  });
    

  return (

    
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        {/* <title>Forgot Password || TravelNinjaz</title> */}
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-2 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {/* Login */}
        </h2>
       

        <div className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`} data-nc-id="SectionSubscribe2" >
          <div className="flex-grow">
            <NcImage src={SignInImage} />
          </div> 
          <div className="flex-shrink-0 mb-10 lg:mb-0 lg:ml-10 lg:w-2/5 sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
            
              <h2 className="font-semibold text-4xl">Reset Password</h2>
                        
              {/* FORM */}
            
              <form
                 className="grid grid-cols-1 gap-6"
                  action="#"
                  onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                  }}
                >
              
                <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                    Email
                    </span>
                    <Input
                      id="username"
                      type="email"
                      placeholder=""
                      className="mt-1"
                      required={true}
                      onChange={validation.handleChange}
                      value={validation.values.username}
                      onBlur={validation.handleBlur}
                      autoComplete={"off"} 
                    />

                    {validation.touched.username &&
                          validation.errors.username ? (                       
                          <p className="validationError" style={{ color: "red" }}>
                            {validation.errors.username.toLocaleString()}
                          </p>
                    ) : null}                  

                </label>    
             
                <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                             Password
                            </span>

                          <UserPasswordInput
                          id="password"
                          name="password"
                          type={passwordShow ? "text" : "password"}
                          className="mt-1" 
                          placeholder="Password"
                          required={true}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password}
                        />

                         {
                        validation.errors.password ? (                       
                          <p className="validationError" style={{ color: "red" }}>
                          {validation.errors.password.toLocaleString()}
                        </p>
                        ) : null}
                        
                        </label>     

                        <label className="block">

                        <span className="text-neutral-800 dark:text-neutral-200">
                             Confirm Password
                            </span>

                    <UserPasswordInput
                     id="confirmpassword"
                     name="confirmpassword"
                     type={passwordShow ? "text" : "password"}
                     className="mt-1" 
                     placeholder="Confirm Password"
                     required={true}
                     onChange={validation.handleChange}
                     onBlur={validation.handleBlur}
                     value={validation.values.confirmpassword}
                 />

                    {
                      validation.errors.confirmpassword ? (
                        <p className="validationError" style={{ color: "red" }}>
                        {validation.errors.confirmpassword.toLocaleString()}
                      </p>
                      ) : null}
                   </label>    

                {errorMessage !== undefined ? (
                        <p className="validationError" style={{ color: "red" }}>
                        {errorMessage}
                    </p>
                        ) : (
                          ""
                        )}
                <ButtonPrimary type="submit" loading={isLoading}>Submit</ButtonPrimary>
              </form>
                    <div>                              
          
                 

          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Wait, I remember my password... {` `}
            <Link to="/login" className="underline">Click here</Link>
          </span>

            </div>
    </div>
      </div>
    </div>
  );
};

export default ResetPassword;
