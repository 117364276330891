import React, { FC, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link, useNavigate } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import PackageGallerySlider from "components/PackageGallerySlider/PackageGallerySlider";
import convertNumbThousand from "utils/convertNumbThousand";
import { currencyIcon } from "services/services/common/const";
import { removePackageFavoriteFromHeader, savePackageFavoriteFromHeader } from "services/services/globalmaster/packageServices";
import { errornotify, successnotify } from "components/common/Notification";

export interface StayCardHProps {
    className?: string;
    data?: any;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const PackageFilterCard: FC<StayCardHProps> = ({
    className = "",
    data,
}) => {
    const {
        package_price,
        package_name,
        destination_name,
        package_description,
        package_id,
        destination_type_id,
        destination_id,
        imageList,
        packageCityList,
        is_best_selling,
        is_family_package,
        is_honeymoon_package,
        row_created_by,
        package_quotation_id,
        is_favourite
        
    } = data;
    const [likedState, setLikedState] = useState(is_favourite)
    const [packageQuotationId, setPackageQuotaionId] = useState(package_quotation_id)
    const href = likedState ? `/packageDetails/${package_id}?packageQuotationId=${packageQuotationId}&userFavorite=${true}` : `/packageDetails/${package_id}`
    const navigate = useNavigate();
    const onClickBtnHandller = async (isLiked: any) => {
        try {
            if (isLiked) {
                const addFavorite: any = await savePackageFavoriteFromHeader({
                    Destination_type_id: destination_type_id,
                    Destination_id: destination_id,
                    Package_id: package_id,
                    Package_calculate_price: package_price,
                    Row_created_by: row_created_by,
                })

                if (addFavorite?.status === "SUCCESS") {
                    setPackageQuotaionId(parseInt(addFavorite?.data[1]))
                    setLikedState(isLiked)
                    successnotify("Added to favorite successfully !")
                }
                else {
                    throw addFavorite?.message;
                }
            } else {
                const removeFavorite: any = await removePackageFavoriteFromHeader({
                    Destination_type_id: destination_type_id,
                    Destination_id: destination_id,
                    Package_id: package_id,
                    Package_quotation_id: packageQuotationId,
                    Row_created_by: row_created_by,
                })
                if (removeFavorite?.status === "SUCCESS") {
                    setLikedState(isLiked)
                    successnotify("Remove from favorite successfully !")
                }
                else {
                    throw removeFavorite?.message;
                }
            }
        } catch (error) {
            errornotify(error)
        }
    }
    const renderSliderGallery = () => {
        return (
            <div className="relative flex-shrink-0 w-full md:w-72 ">
                <PackageGallerySlider
                    ratioClass="aspect-w-6 aspect-h-5"
                    galleryImgs={imageList}
                    uniqueID={`StayCardH_${package_id}`}
                    href={href}
                    package_id={package_id}
                />
                <BtnLikeIcon onClickBtnHandller={onClickBtnHandller} isLiked={likedState} className="absolute right-3 top-3" />
                {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
            </div>
        );
    };

    const renderPackageCities = () => {
        return (
            <>
                
                {/* <div className="inline-flex space-x-3">
                    {packageCityList?.map((item: any, index: any) => {
                        let nights = 0;
                        item?.packageCityHotelList?.map((itemH: any, index: any) => {
                            nights += itemH?.nights;
                        })
                        return (
                            <>
                                <Badge
                                    name={
                                        <div className="flex items-center">
                                            <span className="ml-1">{nights}N {item.city_name}</span>
                                        </div>
                                    }
                                />
                                {index < packageCityList?.length - 1 && (
                                    <i className="text-mute las la-arrow-right mt-1" />
                                )}
                            </>

                        );
                    })}
                </div>               */}

<div className="inline-flex space-x-3">

                  {}
                    {packageCityList?.map((item: any, index: any) => {
                        let nights = 0;
                        item?.packageCityHotelList?.map((itemH: any, index: any) => {
                            nights += itemH?.nights;
                        })
                        return (
                            <>
                                <Badge
                                    name={
                                        <div className="flex items-center text-xxs">
                                            <span className="ml-1">{nights}N {item.city_name}</span>
                                        </div>
                                    }
                                />
                                {index < packageCityList?.length - 1 && (
                                    <i className="text-mute las la-arrow-right mt-1 text-xxs" />
                                )}
                            </>

                        );
                    })}
                </div>     
            </>
        );
    };

    const renderContent = () => {
        return (
            <div className="flex-grow p-3 sm:p-5 flex flex-col">
                <div className="space-y-2">
                    <div className="flex justify-between items-end">

                        <div className="inline-flex space-x-3">
                            {is_best_selling ? <Badge
                                name={
                                    <div className="flex items-center">
                                        <i className="text-xxs las la-star"></i>
                                        <span className="ml-1">Best Selling</span>
                                    </div>
                                }
                            /> : ""}
                            {is_family_package ? <Badge
                                name={
                                    <div className="flex items-center">
                                        <i className="text-xxs las la-user-friends"></i>
                                        <span className="ml-1">Family</span>
                                    </div>
                                }
                                color="yellow"
                            /> : ""}
                            {is_honeymoon_package ? <Badge
                                name={
                                    <div className="flex items-center">
                                        <i className="text-xxs lab la-gratipay"></i>
                                        <span className="ml-1">Honeymoon</span>
                                    </div>
                                }
                                color="green"
                            /> : ""}
                        </div>
                        {/* <span className="text-base font-semibold text-secondary-500">
                            {currencyIcon}{convertNumbThousand(package_price)}
                            {` `}
                            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                                /2 PAX
                            </span>
                        </span> */}
                    </div>
                    <div className="flex items-center space-x-2">
                        {/* {isAds && <Badge name="ADS" color="green" />} */}
                        <Link to={href}>   <h2 className="text-lg font-medium capitalize">
                            <span className="line-clamp-1">{package_name}</span>
                        </h2>
                        </Link>
                    </div>
                </div>
                <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-2"></div>
                {renderPackageCities()}
                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-2"></div>
                <div className="flex justify-between items-end">
                    <StartRating reviewCount={200} point={4.7} />
                    <span className="text-base font-semibold text-secondary-500">
                            {currencyIcon}{convertNumbThousand(package_price)}
                            {` `}
                            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                                /2 PAX
                            </span>
                    </span>
                </div>
                <div className="w-full border-b border-neutral-300 dark:border-neutral-800 my-1"></div>
                <div className="flex justify-between items-end my-1">
                <Link to={href}>   
                    <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
                       Enquire Now
                    </span>
                    </Link>
                    <Link to={href}>   
                    <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">
                        Check Price
                    </span>
                    </Link>
                </div>
            </div>
        );
    };

    return (
        <div
            className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
            data-nc-id="StayCardH"
        >

            <div className="grid grid-cols-1 md:flex md:flex-row ">
                {renderSliderGallery()}
                {renderContent()}
            </div>
        </div>
    );
};

export default PackageFilterCard;
