export const NUMBER_OF_NIGHTS = "NUMBER_OF_NIGHTS";
export const MINIMUM_PRICE = "MINIMUM_PRICE";
export const MAXIMUM_PRICE = "MAXIMUM_PRICE";
export const ISHONEYMOON_PACKAGE = "ISHONEYMOON_PACKAGE";
export const ISFAMILY_PACKAGE = "ISFAMILY_PACKAGE";
export const ISBESTSELLING_PACKAGE = "ISBESTSELLING_PACKAGE";
export const CITYOPTION_FILTER = "CITYOPTION_FILTER";
export const PKGREVIEW_RATE = "PKGREVIEW_RATE";
export const NUMBER_OF_HOTELS = "NUMBER_OF_HOTELS";
export const CITY_HOTEL_SITESEEINGLIST = "CITY_HOTEL_SITESEEINGLIST";
export const DESTINATION_LIST = "DESTINATION_LIST";
export const PACKAGE_DETAILS_PARTICULAR = "PACKAGE_DETAILS_PARTICULAR";