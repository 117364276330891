import React from 'react'
interface Props extends React.PropsWithChildren<any> {
    message: string;
}
const RequiredMessage: React.FC<Props> = ({ message }) => {
    return (
        <>
            <p style={{ color: "red", fontSize: "14px" }}>{message}</p>
        </>
    )
}

export default RequiredMessage