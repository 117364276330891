import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Contact Us",
    menus: [
      {
        href: "#", label: "+ 91 9153438888"
      },
      {
        href: "#", label: "info@travelninjaz.com"
      },
      {
        href: "#", label: "1207, Shivalik Shilp, Iscon Cross Road, Satellite, Ahmedabad- 380059, INDIA"
      }      
    ],
  },
  {
    id: "1",
    title: "Support",
    menus: [
      { href: "#", label: "Contact Us" },
      { href: "#", label: "About Us" },   
      { href: "#", label: "Our Blogs" }     
    ],
  } 
  
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm  py-5">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-20 lg:py-20 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-3 lg:grid-cols-3 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>          
        </div>
        
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>

      {/* <div className={`container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 flex flex-col lg:flex-row lg:items-center`} data-nc-id="SectionSubscribe2" >
          <div className="flex-grow">
            <div className="col-span-3 md:col-span-1">
              <Logo />
            </div>
            <div className="col-span-3 flex items-center md:col-span-3">
              <SocialsList1 className="flex items-center space-x-4 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
            </div>
          </div> 
            <div className="flex-shrink-0 mb-10 lg:mb-0 lg:ml-10 lg:w-2/5 sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
            </div>
       </div> */}
    </div>
  );
};

export default Footer;
