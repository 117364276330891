import React, { FC, useState } from "react";

//Input controls
import Input from "shared/Input/Input";

//Images
import eyeopen from "images/eye-close-up.png";
import eyeclose from "images/eye-close.png";

export interface UserPasswordInputProps {
  id: string,
  name: string,
  type: string,
  className: string,
  placeholder: string,
  required: boolean,
  onChange: any;
  onBlur: any;
  value: string;
}

const UserPasswordInput: FC<UserPasswordInputProps> = ({
  id = "",
  name = "",
  type = "",
  className ="",
  placeholder = "",
  required = false,
  onChange,
  onBlur, 
  value = "",         
    }) => {

  const [passwordShow, setPasswordShow] = useState(false);


  return (

    
    <div className="relative">
                  
    <Input 
        id={id}
        name={name}
        type={passwordShow ? "text" : "password"}
        className={className} 
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
    />

        

  <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
  
  <img
         onClick={() => setPasswordShow(!passwordShow)}
          className="position-absolute "
          src={passwordShow ? eyeclose : eyeopen}
          alt=""
          style={{
            height: "20px",
            width: "20px",
            top: "33px",
            right: "20px",
            cursor: "pointer",
          }}
        />
  </div>   
</div>
  );
};

export default UserPasswordInput;
