import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React, { useEffect, useState } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BestSellingPackage from "./BestSellingPackage";
import { GetDestinationList, GetDestinationPackageCountList } from "services/services/globalmaster/destintionServices";
import { errornotify } from "components/common/Notification";
import { Helmet } from "react-helmet";
import { applicationName } from "services/services/common/const";
// import { getSessionUsingSessionStorage } from "services/services/common/session";
import SectionDestinationImage from "components/SectionHero/SectionDestinationImage";
import SectionHero4 from "components/SectionHero/SectionHero4";
import SectionHero5_full from "components/SectionHero/SectionHero5_full";

function PageHome() {

  const [destinationList, setDestinationList] = useState(undefined);
  const [destinationListWithPackageCount, setDestinationListWithPackageCount] = useState(undefined)
  const [userDetails, setUserDetails] = useState();

  /**This function for get data */
  const getData = async () => {
    try {
      const dtResposne = await GetDestinationList()
      if (dtResposne?.data?.status === "SUCCESS") {
        const destination = dtResposne?.data?.data;
        const active_destinations = destination.filter((destination: any) => destination.is_active);
        setDestinationList(active_destinations);
        const dtResponseWithCount = await GetDestinationPackageCountList();
        if (dtResponseWithCount?.data?.status === "SUCCESS") {
          const destinationWithPkgCount = dtResponseWithCount?.data?.data;
          // const active_pkg_destinations = destinationWithPkgCount.filter((item: any, index: any) => {
          //   return  item?.is_best_selling;
          // });
          setDestinationListWithPackageCount(destinationWithPkgCount);
        }
      }
      else {
        throw dtResposne?.message;
      }
    } catch (error) {
      errornotify(error)
    }

  }
  useEffect(() => {

    // debugger;

    // const promise = getSessionUsingSessionStorage();
    //  /**check authentication */
    //  promise
    //  .then(function (value: any) {
    //    return value;
    //  })
    //  .then((value: any) => {
    //   debugger;
    //    setUserDetails(value);
    //    return value;
    //  });

    getData()


  }, [])
  return (
    <div className="nc-PageHome relative overflow-hidden" >
      <Helmet>
        <title>{applicationName} || Home</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

    

      <div className="container px-1 sm:px-4 mb-24 ">
        <SectionDestinationImage className="" />
      </div>


      {/* <SectionHero5_full destinationImage={""}  ></SectionHero5_full> */}
      {/* <SectionHero className="pt-10 lg:pt-16 lg:pb-16" /> */}

      <div className="container relative space-y-24  mt-24  mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        {/* <SectionHero className="pt-10 lg:pt-16 lg:pb-16" /> */}

      
         {/* Commented below - Transparent Header trial Manisha */}
         {/* <SectionHero4 className="" />  */}
       

        {/* SECTION 1 */}
         {/*destinationListWithPackageCount*/}
        <SectionSliderNewCategories
          categories={destinationListWithPackageCount}
          uniqueClassName="PageHome_s1"
        />
        {/**Best selling packages */}
        <div className="relative py-16">
          <BackgroundSection />
          <BestSellingPackage destinationList={destinationList} />
        </div>


        {/* SECTION */}
        <SectionHowItWork />
        {/* SECTION2 */}
        <SectionOurFeatures />

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlaces />
        </div> */}


        {/* SECTION 1 */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Suggestions for discovery"
            subHeading="Popular places to stay that Chisfis recommends for you"
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div> */}

        {/* SECTION */}
        {/* <SectionSubscribe2 /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}

        {/* SECTION */}
        {/* <SectionGridCategoryBox /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div> */}

        {/* SECTION 1 */}
        {/* <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome_s3"
        /> */}

        {/* SECTION */}
        <SectionVideos /> 

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div>
      </div>
    </div>
  );
}

export default PageHome;
