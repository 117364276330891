import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

//Images
import SignUpImage from "images/SignIn_3.png";

//Success Screen
import SuccessScreen from "../../../components/User/UserSuccessScreen"


export interface PageSignUpSuccessProps {
  className?: string;
}

const PageSignUpSuccess: FC<PageSignUpSuccessProps> = ({ className = "" }) => {

const navigate = useNavigate();
const onClickHandller = () => {
  navigate("/login");
};
return (
  <>
    <SuccessScreen
      className=""
      onClickHandller={onClickHandller}
      status = {"SUCCESS"}
      statusMessage={"Reset link sent successfully !"}
      descriptionMessage={"Please check your email."}
      submitButtonText={""}    
      image = {SignUpImage}    
      supportDescription = {""}
    />
  </>
);
 
};

export default PageSignUpSuccess;
