import React, { useEffect, useRef, useState } from "react";

// Manisha - Change 9 ---------------------
interface Props extends React.PropsWithChildren<any> { }
const TableHeadingContent: React.FC<Props> = ({
  headingName,
  contents,
  listContents,
  isInclude,
  isEXclude,
  cityHotelList,
}) => {

  return (
    <>
      <tr>
        <td width={"20%"} className="listStyleHeadingPdf">
          {headingName}
        </td>
        <td>

          {contents ? (
            <ul>{contents}</ul>
          ) : (
            ""
          )}

          {listContents ? (
            <ul>
              {listContents
                ?.filter((item: any, index: any) => {
                  return isInclude ? item.is_include : !item.is_include;
                })
                ?.map((item: any, index: any) => {

                  return <li key={index}>{item?.inclusions}</li>;
                })}
            </ul>
          ) : (
            ""
          )}
          {/* {cityHotelList ? (
            <ul>
              {cityHotelList?.map((cityItem, index) => {  
                 return  <li> {cityItem?.city_name  + " " + cityItem?.hotel?.hotel_name } </li>               
              })}
            </ul>
          ) : (
           ""
          )} */}

        </td>
      </tr>
    </>
  );
};

export default TableHeadingContent;
