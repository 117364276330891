import React from "react";

interface Props extends React.PropsWithChildren<any> { }
const HeaderPdf: React.FC<Props> = ({
  companyName,
  mobileNumber,
  email,
  proposalId,
  pax,
  leadName,
}) => {
  return (
    <>
      <div className="flex justify-between mt-5 mb-5">
        <div>
          <h1 className="headerCompanyName mb-3">{companyName}</h1>
          <h6 className="mt-1">
            Contact <span className="spanStyle">: {mobileNumber}</span> | Mail{" "}
            <span className="spanStyle">: {email}</span>
          </h6>
        </div>
        <div >
          <h6>
            Proposal ID <span className="spanStyle"> : {proposalId}</span>
          </h6>
          <h6>
            Pax <span className="spanStyle"> : {pax}</span>
          </h6>
          <h6>
            Lead Name <span className="spanStyle">: {leadName}</span>
          </h6>
        </div>
      </div>
      <hr />
    </>
  );
};

export default HeaderPdf;
