import React from "react";
import HeaderPdf from "./Packagepdf/HeaderPdf";
import PriceAndActivities from "./Packagepdf/PriceAndActivities";
import TablePdfContent from "./Packagepdf/TablePdfContent";
export interface PackagePdfCommonProps {
  companyName?: string,
  email?: any;
  leadName?: any;
  mobileNumber?: any;
  pax?: any;
  proposalId?: any;
  totalActivities?: any;
  totalHotel?: any;
  totalPax?: any;
  totalPerson?: any;
  totalPrice?: any;
  totalTransfers?: any;
  numberOfNights?: any;
  destinationName?: any;
  hotels?: any;
  travelDate?: any;
  inclusions?: any;
  cityHotelList?: any;
  packageGuideLines?: any;
  packageItineraryList?: any;
}

const PackagePdfCommon: React.FC<PackagePdfCommonProps> = ({
  companyName,
  email,
  leadName,
  mobileNumber,
  pax,
  proposalId,
  totalActivities,
  totalHotel,
  totalPax,
  totalPerson,
  totalPrice,
  totalTransfers,
  numberOfNights,
  destinationName,
  hotels,
  travelDate,
  inclusions,
  cityHotelList,
  packageGuideLines,
  packageItineraryList
}) => {
  return (

    <div className="m-2 printPackagePdf">
      <HeaderPdf
        companyName={companyName}
        email={email}
        leadName={leadName}
        mobileNumber={mobileNumber}
        pax={pax}
        proposalId={proposalId}
        key={1}
      />
      <PriceAndActivities
        totalActivities={totalActivities}
        totalHotel={totalHotel}
        totalPax={totalPax}
        totalPerson={totalPerson}
        totalPrice={totalPrice}
        totalTransfers={totalTransfers}
      />
      <TablePdfContent
        numberOfNights={numberOfNights}
        destinationName={destinationName}
        hotels={hotels}
        travelDate={travelDate}
        inclusions={inclusions}
        cityHotelList={cityHotelList}
        packageGuideLines={packageGuideLines}
        packageItineraryList={packageItineraryList}
      />
    </div>
  );
};

export default PackagePdfCommon;
