import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
//import imagePng from "images/our-features.png";
import imagePng from "images/travelhero2.png";
import { url } from "services/services/common/const";

export interface SectionHero5_fullProps {
  className?: string;
  destinationImage?: string;

}

const SectionHero5_full: FC<SectionHero5_fullProps> = ({
       className = "",
       destinationImage = ""
      }) => {

if (destinationImage) {
      return (
        <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-6 ">
            <img
              className="absolute inset-0 object-cover rounded-xl"
              src={destinationImage ? `${url}/Images/destination/${destinationImage}` : imagePng}
              alt="hero"
            />
          </div>
      );
    }
    else
    {
      return (
        <div
      className={`nc-SectionHero3 relative ${className}`}
      data-nc-id="SectionHero3"
    >
      <div className="absolute z-10 inset-x-0 top-[10%] sm:top-[15%] text-center flex flex-col items-center max-w-2xl mx-auto space-y-4 lg:space-y-5 xl:space-y-8">
        <span className="sm:text-lg md:text-xl font-semibold text-neutral-900">
          Book and just relax with TravelNinjaz platform
        </span>
        <h2 className="font-bold text-black text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl !leading-[115%] ">
          New generation <br /> of booking
        </h2>
        <ButtonPrimary
          sizeClass="px-6 py-3 lg:px-8 lg:py-4 rounded-xl"
          fontSize="text-sm sm:text-base lg:text-lg font-medium"
        >
          Keep calm & travel on
        </ButtonPrimary>
      </div>
      <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8 ">
        <img
          className="absolute inset-0 object-cover rounded-xl"
          src={imagePng}
          alt="hero"
        />
      </div>
    </div>
 
//  <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-6 ">
//  <img
//    className="absolute inset-0 object-cover rounded-xl"
//    src={destinationImage ? `${url}/Images/destination/${destinationImage}` : imagePng}
//    alt="hero"
//  />
// </div>

      );

    }


  // return (  
  //     //   <div id="bgImageRoot"
  //     //   //  className="main-banner-2"      
  //     //   // style={{ backgroundImage: `${url}/Images/destination/${destinationImage}` }}
  //     //   style={{ backgroundImage:"https://localhost:44395/Images/destination/p-1.43d43fdd.png" }}


  //     // >

  //     //</div>

  //     //  <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-6 ">
  //     //   <img
  //     //     className="absolute inset-0 object-cover rounded-xl"
  //     //     src={destinationImage ? `${url}/Images/destination/${destinationImage}` : imagePng}
  //     //     alt="hero"
  //     //   />
  //     // </div>
     
};

export default SectionHero5_full;
