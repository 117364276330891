import React, { FC, useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import {  userVerifyEmail } from "../../../services/services/User/userService";


//Images
import SignUpImage from "images/SignIn_3.png";

//Success Screen
import UserSuccessScreen from "../../../components/User/UserSuccessScreen"

//Error Screen
import UserErrorScreen from "../../../components/User/UserErrorScreen"

export interface PageSignUpSuccessProps {
  className?: string;
}

const PageSignUpSuccess: FC<PageSignUpSuccessProps> = ({ className = "" }) => {

const [searchParams] = useSearchParams();
const token = searchParams.get("t");
const email = searchParams.get("e");
const [errorMessage, setErrorMessage] = useState("");
const [isLoading, setIsLoading] = useState(false);
const [verificationSuccess, setVerificationSuccess] = useState(false);
const navigate = useNavigate();

const onClickHandller = () => {
  navigate("/login");
};

  /**Vefify Email */
  const verifyEmail = async () => {
    setIsLoading(true);
    try {
      const response = await userVerifyEmail({
        Email: email,
        Token: token,
      });
      if (response.status === "Success") {
        setErrorMessage("");
        setVerificationSuccess(true);
      }
    } catch (error:any) {
      setErrorMessage(error);
      setVerificationSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

useEffect(() => {
  if (token && email) {
    verifyEmail();
  }
}, [token, email]);


return (
  <>
    {/* {isLoading ? <PreloaderArea /> : ""} */}
    {verificationSuccess ? (
      <UserSuccessScreen
      className=""
      onClickHandller={onClickHandller}
      status = {"SUCCESS"}
      statusMessage={"Email Verified Successfully!"}
      descriptionMessage={"Now you can sign in."}
      submitButtonText={"Sign in"}    
      image = {SignUpImage}    
      supportDescription = {""}
    />
    ) : (
      <UserErrorScreen
          submitButtonText={""}
          descriptionMessage={errorMessage}
          statusMessage={"Email Verification Failed !"}
          supportDescription={"If you experience any problem accessing the system, please contact our support team."} 
          className={""} status={""} image={""}       
           // onClickHandller={verifyEmail}
      />
    )}
  </>
);
 
};

export default PageSignUpSuccess;
