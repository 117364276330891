import React, { FC } from "react";

export interface CheckboxProps {
  label?: string;
  subLabel?: string;
  className?: string;
  name: string;
  id?: any;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  value?: any;
}

const Checkbox: FC<CheckboxProps> = ({
  subLabel = "",
  label = "",
  name,
  className = "",
  defaultChecked,
  onChange,
  id,
  value
}) => {
  return (
    <div className={`flex text-sm sm:text-base ${className}`}>
      <input
        id={id}
        name={name}
        type="checkbox"
        className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
        defaultChecked={defaultChecked}
        onChange={(e: any) => onChange && onChange(e)}
        // checked={defaultChecked}
        value={value}

      />
      {label && (
        <label
          htmlFor={id}
          className="ml-3.5 text-sm  flex flex-col flex-1 justify-center"
        >
          <span className="text-sm text-neutral-900 dark:text-neutral-100">
            {label}
          </span>
        </label>
      )}
    </div>
  );
};

export default Checkbox;
