import { CITYOPTION_FILTER, CITY_HOTEL_SITESEEINGLIST, DESTINATION_LIST, ISBESTSELLING_PACKAGE, ISFAMILY_PACKAGE, ISHONEYMOON_PACKAGE, MAXIMUM_PRICE, MINIMUM_PRICE, NUMBER_OF_HOTELS, NUMBER_OF_NIGHTS, PACKAGE_DETAILS_PARTICULAR, PKGREVIEW_RATE } from "./actionType";
const initialState = {
    numberOfNights: 0,
    minimumPrice: 0,
    maximumPrice: 0,
    isBestSellingFilter: false,
    isFamilyPackageFilter: false,
    isHoneymoonPackageFilter: false,
    cityOptionsValue: [],
    itrCityDetails: [],
    destinationList: [],
    packageDetails: {},
    pkgReviewRate: 2,
    numberOfHotels: 0,
};
export const selectOptionFilterReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case NUMBER_OF_NIGHTS:
            return {
                ...state,
                numberOfNights: action.payload,
            };

        case MINIMUM_PRICE:
            return {
                ...state,
                minimumPrice: action.payload,
            }
        case MAXIMUM_PRICE:
            return {
                ...state,
                maximumPrice: action.payload,
            }
        case ISFAMILY_PACKAGE:
            return {
                ...state,
                isFamilyPackageFilter: action.payload,
            }
        case ISBESTSELLING_PACKAGE:
            return {
                ...state,
                isBestSellingFilter: action.payload,
            }
        case ISHONEYMOON_PACKAGE:
            return {
                ...state,
                isHoneymoonPackageFilter: action.payload,
            }
        case CITYOPTION_FILTER:
            return {
                ...state,
                cityOptionsValue: action.payload,
            }
        case PKGREVIEW_RATE:
            return {
                ...state,
                pkgReviewRate: action.payload,
            }
        case NUMBER_OF_HOTELS:
            return {
                ...state,
                numberOfHotels: action.payload,
            }
        case CITY_HOTEL_SITESEEINGLIST:
            return {
                ...state,
                itrCityDetails: action.payload
            }
        case DESTINATION_LIST:
            return {
                ...state,
                destinationList: action.payload
            }
        case PACKAGE_DETAILS_PARTICULAR:
            return {
                ...state,
                packageDetails: action.payload
            }
        default:
            return state;
    }
};
