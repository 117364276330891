import { url } from "../common/const";
import { get, post } from "../common/https";
/**This service for get destination list*/
export const GetDestinationList = () => {
  const getdata = get(`${url}/api/Destination/list`);
  return getdata;
};
/**This  srevice for get destination list with package count */
export const GetDestinationPackageCountList = () => {
  const getdata = get(`${url}/api/Destination/DestinationPackageCountList`);
  return getdata;
};