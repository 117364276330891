import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "components/Footer/Footer";
import Footer2 from "components/Footer/Footer2";
import SocialsList1 from "shared/SocialsList1/SocialsList1";

import {
  ShoppingBagIcon as ShoppingCartIcon,
  Cog8ToothIcon as CogIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import { PathName } from "routers/types";
import { applicationName } from "services/services/common/const";

export type SiteFooters = "Footer 1" | "Footer 2";


let OBSERVER: IntersectionObserver | null = null;
const PAGES_HIDE_FOOTER_BORDER: PathName[] = [
  "/home-3",
  "/listing-car-detail",
  "/listing-experiences-detail",
  "/listing-stay-detail",
];

const PAGES_HIDE_HEADER_FOOTER: PathName[] = [
  "/home", 
  "/filterPackage/:destinationId",
  "/packageDetails/:packageId",
];

const SiteFooter = () => {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  let [Footers] = React.useState<SiteFooters[]>([
    "Footer 1",  //Footer with Logo and all System menus
    "Footer 2",  //Footer with Logo only
  ]);  

  const [FooterSelected, setFooterSelected] =
    React.useState<SiteFooters>("Footer 2");
  const location = useLocation();

  useEffect(() => {   
   
    if (
        location.pathname === "/" || 
        location.pathname === "/login" || 
        location.pathname === "/signup" || 
        location.pathname === "/signup-success" ||
        location.pathname === "/user-email-verify" ||
        location.pathname === "/forgotpassword" ||
        location.pathname === "/forgotpassword-success" ||
        location.pathname === "/resetpassword" ||
        location.pathname === "/resetpassword-success" ||
        location.pathname === "/page404" 
        ) {
          setFooterSelected("Footer 2");
    } 
   else if (
        location.pathname.indexOf("/home/") ||
        location.pathname.indexOf("/packageDetails/") ||
        location.pathname.indexOf("/filterPackage/") 
       ) {
      setFooterSelected("Footer 1");
    }
    else 
    {
      setFooterSelected("Footer 2");  
    }  
    // else{
    //   if (PAGES_HIDE_HEADER_FOOTER.includes(location.pathname as PathName )) {
    //     setFooterSelected("Footer 1");
    //   }
    //   else 
    //   {
    //     setFooterSelected("Footer 2");
    //   }  
    // }   
   
  }, [location.pathname]);

 
  const renderFooter = () => {
    let headerClassName = "shadow-sm dark:border-b dark:border-neutral-700";    

    switch (FooterSelected) {
      case "Footer 1":
        return <Footer />;
      case "Footer 2":
        return <Footer2 />;
   
      default:
        return <Footer2/>;
    }
  };

  return (
    <>    
      {renderFooter()}    
    </>
  );

};

export default SiteFooter;
