import NcInputNumber from 'components/NcInputNumber/NcInputNumber';
import RequiredMessage from 'components/common/RequiredMessage';
import FormItem from 'containers/PageAddListing1/FormItem';
import React, { useEffect, useState } from 'react'
import { currencyIcon } from 'services/services/common/const';
import Select from 'shared/Select/Select';
import convertNumbThousand from 'utils/convertNumbThousand';
interface Props extends React.PropsWithChildren<any> {
    hotelList?: any;
    selectedHotelId?: any;
    selectedMealPlan?: any;
    index?: any;
    activeKey?: any;
    // onChangeEventHandller?: () => void;
}
const HotelMealPlanNights: React.FC<Props> = ({ packageCityId, onChangeEventHandller, nights, selectedMealPlan, selectedHotelId, activeKey, index, hotelList, isRequiredField }) => {
    const [mealPlanList, setMealPlanList] = useState<any>([])
    const [tempSelectedHotelId, setTempSelectedHotelId] = useState(selectedHotelId)
    const [tempSelectedMealPlan, setTempSelectedMealPlan] = useState(selectedMealPlan)
    /**This useEffect set meal plan list according to selected hotel id */
    useEffect(() => {
        if (tempSelectedHotelId) {
            // debugger;
            setMealPlanList(
                hotelList?.find(
                    (itemHotel: any) => itemHotel?.hotel?.hotel_id === parseInt(tempSelectedHotelId)
                )?.hotel?.hotelMealPlanList
            );
        }
    }, [tempSelectedHotelId]);

    const onChangeHandller = (e: any) => {
        if (e.target.name === "hotel_id") {
            setMealPlanList(
                hotelList?.find(
                    (itemHotel: any) => itemHotel?.hotel?.hotel_id === parseInt(e.target.value)
                )?.hotel?.hotelMealPlanList
            );
            setTempSelectedHotelId(e.target.value);
            setTempSelectedMealPlan(undefined);
        } else {
            setTempSelectedMealPlan(e.target.value);
        }
        onChangeEventHandller(e, activeKey);
    }
    return (
        <div>
            <div className=" grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 mb-2">  <FormItem
                label="Hotel"
                isRequired={true}
            >
                <Select id={`selectHotel_${packageCityId}_${index}`}
                    defaultValue={tempSelectedHotelId}
                    name="hotel_id"
                    onChange={(e: any) => onChangeHandller(e)}>
                    <option value={undefined} selected>
                        Select hotel
                    </option>
                    {hotelList?.map((itemHotel: any, index: any) => {
                        return (
                            <option
                                value={itemHotel?.hotel?.hotel_id}
                                key={index}
                                selected={itemHotel?.hotel?.hotel_id === tempSelectedHotelId}
                            >

                                {itemHotel?.hotel?.hotel_name}
                            </option>
                        );
                    })}
                </Select>
                {!tempSelectedHotelId && isRequiredField ? <RequiredMessage message="Hotel is required" /> : ""}
            </FormItem>

                <FormItem className='ml-8 mb-2'
                    label="Nights"
                >
                    <NcInputNumber max={nights} defaultValue={1} onChange={(value) => onChangeEventHandller(undefined, activeKey, value)} />

                </FormItem>
            </div>
            <FormItem
                label="Meal Plans"
                isRequired={true}
            >
                <Select id="selectMealPlans" name="meal_plan_code"
                    defaultValue={tempSelectedMealPlan}
                    onChange={(e) => onChangeHandller(e)}>
                    <option value={undefined} selected>
                        Select Meal plans
                    </option>
                    {mealPlanList?.map((item: any, index: any) => {
                        return (
                            <option
                                value={item.meal_plan_code}
                                key={index}
                                selected={item.meal_plan_code === tempSelectedMealPlan}
                            >
                                {item?.meal_plan_code}
                                (Adult :
                                {currencyIcon} {convertNumbThousand(parseInt(item?.adult_price))}
                                , child/ex adult :
                                {currencyIcon} {convertNumbThousand(parseInt(item?.child_price))}
                                , child (wihout bed):
                                {currencyIcon} {convertNumbThousand(parseInt(
                                    item?.child_price_without_bed ? item?.child_price_without_bed : 0
                                ))}
                                )
                            </option>
                        );
                    })}
                </Select>
                {!tempSelectedMealPlan && isRequiredField ? <RequiredMessage message="Meal plans is required" /> : ""}
            </FormItem>
        </div>
    )
}

export default HotelMealPlanNights