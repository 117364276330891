import React from "react";

// Manisha - Change 10 ---------------------
interface Props extends React.PropsWithChildren<any> { }
const PDFContent_packageItinerary: React.FC<Props> = ({
  headingName,
  packageItineraryList
}) => {

  return (
    <>
      <tr>
        <td width={"20%"} className="listStyleHeadingPdf">
          {headingName}
        </td>
        <td>
          {packageItineraryList ? (

            <table>
              {
                packageItineraryList?.map((item: any, cityIndex: any) => {
                  return <tr key={item.package_itinerary_id}> <b>  {"Day "} {item.day} {" : "} {item.itinerary_title} </b> <br /> {item.itinerary_description}    </tr>
                }
                )}

            </table>

          ) : (
            ""
          )}
        </td>
      </tr>
    </>
  );
};

export default PDFContent_packageItinerary;
