/**base url*/

//Server
export const url = "https://api.b2btrav.com";

//Local
//export const url = "https://localhost:44395";

export const googleClientId =  "649788567312-0geettqbnapr28vgathmpposdv3o5jf7.apps.googleusercontent.com";
export const faceBookAppId = "6482949298392048";
export const currencyIcon = "₹‎"
export const applicationName = "B2B"

export const applicationType = "Client";
