import RequiredMessage from 'components/common/RequiredMessage';
import FormItem from 'containers/PageAddListing1/FormItem'
import React, { useEffect, useState } from 'react'
import { currencyIcon } from 'services/services/common/const';
import ButtonSecondary from 'shared/Button/ButtonSecondary'
import Checkbox from 'shared/Checkbox/Checkbox';
import Select from 'shared/Select/Select'
import convertNumbThousand from 'utils/convertNumbThousand';
export interface PackageDetailsCityProps {
    item?: any;
    pkgCityHOtelmeal?: any;
    onChangeEventHandller?: (e: any, packageCityId: any) => void;
    index?: any;
    onChangeHandllerSiteSeeing?: (e: any,
        key: any,
        packageCityId: any,
        itemSite: any) => void;
    isRequiredField?: any;
}
const PackageDetailsCity: React.FC<PackageDetailsCityProps> = ({ isRequiredField, item, pkgCityHOtelmeal, onChangeEventHandller, onChangeHandllerSiteSeeing, index }) => {

    const filterCityHotelMealPlans = pkgCityHOtelmeal?.filter(
        (itemCity: any, index: any) => {
            return itemCity.package_city_id === item.package_city_id;
        }
    );
    const [mealPlanList, setMealPlanList] = useState([]);
    const [hotelList, setHotelList] = useState(
        filterCityHotelMealPlans[0]?.packageCityHotelList
    );
    const [selectedHotelId, setSelectedHotelId] =
        useState(filterCityHotelMealPlans[0]?.packageCityHotelList[0]?.hotel
            ?.selectedHotelMealPlan?.hotel_id);
    const [selectedMealPlan, setSelectedMealPlan] = useState(
        filterCityHotelMealPlans[0]?.packageCityHotelList[0]?.hotel
            ?.selectedHotelMealPlan?.meal_plan_code
    );
    const packageCityId = filterCityHotelMealPlans[0]?.package_city_id;
    /**This useEffect set meal plan list according to selected hotel id */
    useEffect(() => {
        if (selectedHotelId) {
            // debugger;
            setMealPlanList(
                hotelList?.find(
                    (itemHotel: any) => itemHotel?.hotel?.hotel_id === parseInt(selectedHotelId)
                )?.hotel?.hotelMealPlanList
            );
        }
    }, [selectedHotelId]);

    const onChangeHandller = (e: any, packageCityId: any) => {
        if (e.target.name === "hotel_id") {
            setMealPlanList(
                hotelList?.find(
                    (itemHotel: any) => itemHotel?.hotel?.hotel_id === parseInt(e.target.value)
                )?.hotel?.hotelMealPlanList
            );
            setSelectedHotelId(e.target.value);
            setSelectedMealPlan(undefined);
        } else {
            setSelectedMealPlan(e.target.value);
        }
        onChangeEventHandller && onChangeEventHandller(e, packageCityId);
    };
    let nights = 0;
    item?.packageCityHotelList?.map((itemCity: any, index: any) => {
        nights += itemCity?.nights
    })
    return (
        <div className="listingSection__wrap">
            <div>
                <h2 className="text-2xl font-semibold">City {index + 1} - {item?.city_name} </h2>
            </div>
            <div className=" grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 ">

                {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"> */}
                <FormItem
                    label="Hotel"
                    isRequired={true}
                >
                    <Select id={`selectHotel_${item?.city_id}_${index}`}
                        defaultValue={selectedHotelId}
                        name="hotel_id"
                        onChange={(e) => onChangeHandller(e, packageCityId)}>
                        <option value={undefined} selected>
                            Select hotel
                        </option>
                        {hotelList?.map((itemHotel: any, index: any) => {
                            return (
                                <option
                                    value={itemHotel?.hotel?.hotel_id}
                                    key={index}
                                    selected={itemHotel?.hotel?.hotel_id === selectedHotelId}
                                >

                                    {itemHotel?.hotel?.hotel_name}
                                </option>
                            );
                        })}
                    </Select>
                    {!selectedHotelId && isRequiredField ? <RequiredMessage message="Hotel is required" /> : ""}
                </FormItem>
                <FormItem className='ml-8'
                    label="Nights" desc={nights}
                ></FormItem>
            </div>
            <FormItem
                label="Meal Plans"
                isRequired={true}
            >
                <Select id="selectMealPlans" name="meal_plan_code"
                    defaultValue={selectedMealPlan}
                    onChange={(e) => onChangeHandller(e, packageCityId)}>
                    <option value={undefined} selected>
                        Select Meal plans
                    </option>
                    {mealPlanList?.map((item: any, index: any) => {
                        return (
                            <option
                                value={item.meal_plan_code}
                                key={index}
                                selected={item.meal_plan_code === selectedMealPlan}
                            >
                                {item?.meal_plan_code}
                                (Adult :
                                {currencyIcon} {convertNumbThousand(parseInt(item?.adult_price))}
                                , child/ex adult :
                                {currencyIcon} {convertNumbThousand(parseInt(item?.child_price))}
                                , child (wihout bed):
                                {currencyIcon} {convertNumbThousand(parseInt(
                                    item?.child_price_without_bed ? item?.child_price_without_bed : 0
                                ))}
                                )
                            </option>
                        );
                    })}
                </Select>
                {!selectedMealPlan && isRequiredField ? <RequiredMessage message="Meal plans is required" /> : ""}
            </FormItem>
            <div>
                <label className="text-lg font-semibold" htmlFor="">
                    City Site Seeings
                </label>
                <div className="mt-6 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-5">
                    {item?.siteSeeingList?.map((itemSite: any, i: any) => {
                        return <Checkbox label={`${itemSite?.site} (${currencyIcon} ${convertNumbThousand(parseInt(itemSite?.rate))})`} name={itemSite?.site}
                            onChange={(e) =>
                                onChangeHandllerSiteSeeing && onChangeHandllerSiteSeeing(
                                    e,
                                    itemSite?.key,
                                    packageCityId,
                                    itemSite?.package_quotation_siteseeing_id
                                )}
                            id={`${item.city_id}_${index}_${itemSite?.site}${itemSite?.key}`}
                            value={itemSite?.city_site_seeing_id}
                            defaultChecked={itemSite?.is_selected}
                        />

                    })}


                </div>
            </div>

        </div>
    )
}

export default PackageDetailsCity