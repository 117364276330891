import axios from "axios";
import { getSession, getSessionUsingSessionStorage } from "./session";
let token: any = "";
export const get_old = (url: any, params: any) =>
  new Promise((resolve, reject) => {
    let promise = getSessionUsingSessionStorage();
    promise
      .then(function (value) {
        return value;
      })
      .then((value: any) => {
        token = value && (value.token ? value.token : "");
        //let options = {
        var headers = {
          //Authorization: token ? "Bearer " + token : "",
          Authorization: token ? `Bearer ${token}` : "",
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        //};
        return fetch(url, { headers })
          .then((response) =>
            response.json().then((body) => ({
              ok: response.ok,
              status: response.status,
              statusText: response.statusText,
              data: body,
            }))
          )
          .then((responseJson) => {
            resolve(responseJson);
            if (!responseJson.ok) {
              if (responseJson.status === 400) {
                //400 = bad request
                if (responseJson.data && responseJson.data.message)
                  throw responseJson.data.message;
                else throw responseJson.statusText;
              } else throw responseJson.statusText;
            } else resolve(responseJson.data);
          })
          .catch((error) => {
            sessionStorage.clear();
            reject(error);
          });
      });
  });

export const post_old = (url: any, data = {}, fileToken = "") =>
  new Promise((resolve, reject) => {
    //Original working
    //var userDetails = getSession();

    //New Trial
    var userDetails = getSession("userDetails");
    // let userDetails = getSessionUsingSessionStorage();
    userDetails.then(function (value: any) {
      token = value && (value.token ? value.token : "");
    });
    let options = {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    let postOptions = { ...options.headers };
    if (fileToken.length > 0) {
      postOptions = {
        ...options.headers,
        //Authorization: fileToken ? `Bearer ${fileToken}` : "",
      };
    }
    return fetch(url, {
      method: "POST",
      headers: postOptions,
      body: JSON.stringify(data),
    })
      .then((response) =>
        response.json().then((body) => ({
          ok: response.ok,
          status: response.status,
          statusText: response.statusText,
          data: body,
        }))
      )
      .then((responseJson) => {
        if (!responseJson.ok) {
          if (responseJson.status === 400) {
            //400 = bad request
            if (responseJson.data && responseJson.data.message)
              throw responseJson.data.message;
            else throw responseJson.statusText;
          } else throw responseJson.statusText;
        } else resolve(responseJson.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
/** This method does not have any specific content-type */
export const post_common = (url: any, data: any = {}, fileToken = "") =>
  new Promise((resolve, reject) => {
    var userDetails = getSessionUsingSessionStorage();
    userDetails.then(function (value: any) {
      token = value && (value.token ? value.token : "");
    });
    let options = {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
    let postOptions = { ...options.headers };
    if (fileToken.length > 0) {
      postOptions = {
        ...options.headers,
        //Authorization: fileToken ? `Bearer ${fileToken}` : "",
      };
    }
    return fetch(url, {
      method: "POST",
      headers: postOptions,
      body: data,
    })
      .then((response) =>
        response.json().then((body) => ({
          ok: response.ok,
          status: response.status,
          statusText: response.statusText,
          data: body,
        }))
      )
      .then((responseJson) => {
        if (!responseJson.ok) {
          if (responseJson.status === 400) {
            //400 = bad request
            if (responseJson.data && responseJson.data.message)
              throw responseJson.data.message;
            else throw responseJson.statusText;
          } else throw responseJson.statusText;
        } else resolve(responseJson.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

/***This is post method from axios */
export const post = async (url: any, data: any = {}, fileToken = "") => {
  try {
    debugger;

    let promise: any = await getSessionUsingSessionStorage();
    let response = await axios.post(url, data, {
      headers: { Authorization: `Bearer ${promise.token}` },
    });

    debugger;

    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      localStorage.removeItem("userDetails");
      error.message = "Authorization error";
    } else if (error.response?.status === 400) {
      error.message = error.response.data.message;
    }
    return error;
  }
};

/**This is get method from axios */
export const get = async (url: any, params?: any) => {
  try {
    let promise: any = await getSessionUsingSessionStorage();

    debugger;
    /**this is temporary token for testing remove after */
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${promise.token}` },
    });
    return response;
  } catch (error: any) {
    if (error.response?.status === 401) {
      localStorage.removeItem("userDetails");
      error.message = "Authorization error";
    } else if (error.response?.status === 400) {
      error.message = error.response.data.message;
    }
    return error;
  }
};
// import { getSession } from "./session";

// let token = "";
// var promise = getSession();
// promise.then(function (value) {
//   token = value && (value.token ? value.token : "");
// });

// function getPromise(method, URL) {
//   let promise = new Promise(function (resolve, reject) {
//     let req = new XMLHttpRequest();
//     req.open(method, URL);
//     req.onload = function () {
//       if (req.status == 200) {
//         resolve(req.response);
//       } else {
//         reject("There is an Error!");
//       }
//     };
//     req.send();
//   });
//   return promise;
// }

// export const get = (url) =>
//   new Promise((resolve, reject) => {
//     let promise = getPromise("GET", url);
//     promise
//       .then(function (value) {
//         return value;
//       })
//       .then((value) => {
//         // token = value && (value.token ? value.token : "");
//         let options = {
//           headers: {
//             //Authorization: token ? `Bearer ${token}` : "",
//             Accept: "application/json",
//             "Content-Type": "application/json",
//           },
//         };
//         return fetch(url, { method: "GET", ...options })
//           .then((response) =>
//             response.json().then((body) => ({
//               ok: response.ok,
//               status: response.status,
//               statusText: response.statusText,
//               data: body,
//             }))
//           )
//           .then((responseJson) => {
//             resolve(responseJson);

//             if (!responseJson.ok) {
//               if (responseJson.status === 400) {
//                 //400 = bad request
//                 if (responseJson.data && responseJson.data.message)
//                   throw responseJson.data.message;
//                 else throw responseJson.statusText;
//               } else throw responseJson.statusText;
//             } else resolve(responseJson.data);
//           })
//           .catch((error) => {
//             reject(error);
//           });
//       });
//   });

// // export const post = (url, data) => {
// //   return new Promise(function (resolve, reject) {
// //     var xhr = new XMLHttpRequest();
// //     xhr.open("POST", url);
// //     xhr.onload = function () {
// //       if (xhr.status >= 200 && xhr.status < 300) {
// //         resolve(xhr.response);
// //       } else {
// //         reject({
// //           status: this.status,
// //           statusText: xhr.statusText,
// //         });
// //       }
// //     };
// //     xhr.onerror = function () {
// //       reject({
// //         status: this.status,
// //         statusText: xhr.statusText,
// //       });
// //     };
// //     if (data) {
// //       xhr.send(data);
// //     } else {
// //       xhr.send();
// //     }
// //   });
// // };
// const postPromise = (url, data) => {
//   var xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
//   xmlhttp.open("POST", url, true);
//   xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
//   xmlhttp.onreadystatechange = () => {
//     if (xmlhttp.readyState === 4 && xmlhttp.status === 201) {
//       let obj = JSON.parse(xmlhttp.response);
//       console.log(obj);
//     }
//   };
//   xmlhttp.send(JSON.stringify(data));
// };

// export const post_2 = (url, data = {}) =>
//   new Promise((resolve, reject) => {
//     // let promise = postPromise(url, data);
//     // promise.then(function (value) {
//     //   return value;
//     // });

//     console.log(`post data ${data}`);

//     let options = {
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//     };
//     let postOptions = { ...options.headers };

//     var data_json = JSON.stringify(data);

//     //var data_json = data;

//     //var new_data_json = data_json.replace(/\\/g, "***");
//     //data_json = data_json.replace(/\/+$/, "");
//     //data_json = data_json.replace(/\\|\//g, '');

//     // var data_json =
//     //   "destination_json=[%0A%09{%0A%09%09%22destination_id%22:13,%0A%09%09%22destination_name%22:%22sdfsf%22,%0A%09%09%22destination_type_id%22:1,%0A%09%09%22is_active%22:true,%0A%09%09%22row_created_by%22:%22Admin%22,%0A%09%09%22row_created_date%22:%2212/10/2022%22,%0A%09%09%22row_altered_by%22:%22Admin%22,%0A%09%09%22row_altered_date%22:%2212/10/2022%22%0A%09%09}%09%09%0A%09%0A%09]&operation=A";
//     // debugger;

//     return (
//       fetch(url, {
//         method: "POST",
//         headers: postOptions,
//         body: data_json,
//       })
//         //.then(response => return response.json())
//         //.then(response=> fetchHandler(response))
//         .then((response) =>
//           response.json().then((body) => ({
//             ok: response.ok,
//             status: response.status,
//             statusText: response.statusText,
//             data: body,
//           }))
//         )
//         .then((responseJson) => {
//           if (!responseJson.ok) {
//             if (responseJson.status === 400) {
//               //400 = bad request
//               if (responseJson.data && responseJson.data.message)
//                 throw responseJson.data.message;
//               else throw responseJson.statusText;
//             } else throw responseJson.statusText;
//           } else resolve(responseJson.data);
//         })
//         .catch((error) => {
//           reject(error);
//         })
//     );
//   });

// export const post = (url, data = {}, fileToken = "") =>
//   new Promise((resolve, reject) => {
//     var promise = getSession();
//     promise.then(function (value) {
//       token = value && (value.token ? value.token : "");
//     });
//     let options = {
//       headers: {
//         //Authorization: token ? `Bearer ${token}` : "",
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//     };
//     let postOptions = { ...options.headers };
//     if (fileToken.length > 0) {
//       postOptions = {
//         ...options.headers,
//         //Authorization: fileToken ? `Bearer ${fileToken}` : "",
//       };
//     }
//     return (
//       fetch(url, {
//         method: "POST",
//         headers: postOptions,
//         body: JSON.stringify(data),
//       })
//         //.then(response => return response.json())
//         //.then(response=> fetchHandler(response))
//         .then((response) =>
//           response.json().then((body) => ({
//             ok: response.ok,
//             status: response.status,
//             statusText: response.statusText,
//             data: body,
//           }))
//         )
//         .then((responseJson) => {
//           if (!responseJson.ok) {
//             if (responseJson.status === 400) {
//               //400 = bad request
//               if (responseJson.data && responseJson.data.message)
//                 throw responseJson.data.message;
//               else throw responseJson.statusText;
//             } else throw responseJson.statusText;
//           } else resolve(responseJson.data);
//         })
//         .catch((error) => {
//           reject(error);
//         })
//     );
//   });
