import React, { FC, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import PackageGallerySlider from "components/PackageGallerySlider/PackageGallerySlider";
import { removePackageFavoriteFromHeader, savePackageFavoriteFromHeader } from "services/services/globalmaster/packageServices";
import { errornotify, successnotify } from "components/common/Notification";

export interface StayCardProps {
    className?: string;
    // data?: StayDataType;
    data?: any;
    size?: "default" | "small";
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const PackageCard: FC<StayCardProps> = ({
    size = "default",
    className = "",
    data,
}) => {
    const {
        package_price,
        package_name,
        destination_type_id,
        destination_id,
        destination_name,
        package_id,
        imageList,
        row_created_by,
        packageCityList,
        is_favourite,
        package_quotation_id,
    } = data;
    console.log("data", data)
    const [packageQuotationId, setPackageQuotaionId] = useState(package_quotation_id)
    const [likedState, setLikedState] = useState(is_favourite);
    const href = likedState ? `/packageDetails/${package_id}?packageQuotationId=${packageQuotationId}&userFavorite=${true}` : `packageDetails/${package_id}`
    let nights = 0;
    packageCityList?.map((item: any, index: any) => {
        item?.packageCityHotelList?.map((itemHotel: any, index: any) => {
            nights += itemHotel?.nights;
        })
    })
    const packagePrice = package_price?.toLocaleString(
        "en-IN",
        {
            style: "currency",
            currency: "INR",
        }
    );
    const onClickBtnHandller = async (isLiked: any) => {
        try {
            if (isLiked) {
                const addFavorite: any = await savePackageFavoriteFromHeader({
                    Destination_type_id: destination_type_id,
                    Destination_id: destination_id,
                    Package_id: package_id,
                    Package_calculate_price: package_price,
                    Row_created_by: row_created_by,
                })

                if (addFavorite?.status === "SUCCESS") {
                    setPackageQuotaionId(parseInt(addFavorite?.data[1]))
                    setLikedState(isLiked)
                    successnotify("Added to favorite successfully !")
                }
                else {
                    throw addFavorite?.message;
                }
            } else {
                const removeFavorite: any = await removePackageFavoriteFromHeader({
                    Destination_type_id: destination_type_id,
                    Destination_id: destination_id,
                    Package_id: package_id,
                    Package_quotation_id: packageQuotationId,
                    Row_created_by: row_created_by,
                })
                if (removeFavorite?.status === "SUCCESS") {
                    setLikedState(isLiked)
                    successnotify("Remove from favorite successfully !")
                }
                else {
                    throw removeFavorite?.message;
                }
            }
        } catch (error) {
            errornotify(error)
        }
    }
    const renderSliderGallery = () => {
        return (
            <div className="relative w-full">
                <PackageGallerySlider
                    uniqueID={`StayCard_${package_id}`}
                    ratioClass="aspect-w-4 aspect-h-3 "
                    galleryImgs={imageList}
                    href={href}
                    package_id={package_id}
                />
                <BtnLikeIcon isLiked={likedState} onClickBtnHandller={onClickBtnHandller} className="absolute right-3 top-3 z-[1]" />
                {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
                <div className="space-y-2">
                    <span className="text-sm text-neutral-500 dark:text-neutral-400">
                        {/* {data.name} · {bedrooms} beds */}
                        {/* {nights}/nights */}
                        {nights}N . {nights+1}D
                    </span>
                    <div className="flex items-center space-x-2">
                        {/* {isAds && <Badge name="ADS" color="green" />} */}
                        <Link to={href}>   <h2
                            className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
                                }`}
                        >
                            <span className="line-clamp-1">{package_name}</span>
                        </h2>
                        </Link>
                    </div>
                    <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                        {size === "default" && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                            </svg>
                        )}
                        <span className="">{destination_name}</span>
                    </div>
                </div>
                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
                <div className="flex justify-between items-center">
                    <span className="text-base font-semibold">
                        {packagePrice}
                        {` `}
                        {/* {size === "default" && (
                            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                                /night
                            </span>
                        )} */}
                    </span>
                    <StartRating reviewCount={88} point={4.5} />
                </div>
            </div>
        );
    };

    return (
        <div
            className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
            data-nc-id="StayCard"
        >
            {renderSliderGallery()}
            {renderContent()}
        </div>
    );
};

export default PackageCard;
