import React from "react";
import { FaRegUser, FaTaxi, FaWalking } from "react-icons/fa";
import IconAndactivities from "./IconAndactivities";
import { IoBusinessSharp } from "react-icons/io5";
import convertNumbThousand from "utils/convertNumbThousand";
import { currencyIcon } from "services/services/common/const";
interface Props extends React.PropsWithChildren<any> { }
const PriceAndActivities: React.FC<Props> = ({
  totalPrice,
  totalPerson,
  totalHotel,
  totalActivities,
  totalTransfers,
  totalPax,
}) => {
  return (
    <div className="mt-5 mb-5">
      <div className="flex justify-between align-items-start pb-4">
        <div>
          <h1 className="pricePackagePdf">
            {currencyIcon} {convertNumbThousand(totalPrice)}{" "}
            <span className="perPaxSpan">for {totalPerson} pax</span>
          </h1>{" "}
        </div>
        <div className="flex">
          <IconAndactivities
            icon={<IoBusinessSharp fontSize={20} color="#ec255a" />}
            title={`${totalHotel} hotel`}
          />
          <IconAndactivities
            icon={<FaWalking fontSize={20} color="#ec255a" />}
            title={`${totalActivities} Activities`}
          />
          <IconAndactivities
            icon={<FaTaxi fontSize={20} color="#ec255a" />}
            title={`${totalTransfers} Transfers`}
          />
          <IconAndactivities
            icon={<FaRegUser fontSize={20} color="#ec255a" />}
            title={`${totalPax} Pax`}
          />
        </div>
      </div>
      <hr />
    </div>
  );
};

export default PriceAndActivities;
