import React from "react";

// Manisha - Change 10 ---------------------
interface Props extends React.PropsWithChildren<any> { }
const PDFContent_packageGuidelines: React.FC<Props> = ({
  headingName,
  packageGuideLines
}) => {

  return (
    <>
      <tr>
        <td width={"20%"} className="listStyleHeadingPdf">
          {headingName}
        </td>
        <td>
          {packageGuideLines ? (
            //  <div className="listStylePdf">{packageGuideLines}</div>
            //  <div dangerouslySetInnerHTML={htmlDecode(packageGuideLines) } />
            <div dangerouslySetInnerHTML={{ __html: packageGuideLines }} />

          ) : (
            ""
          )}
        </td>
      </tr>
    </>
  );
};

export default PDFContent_packageGuidelines;
