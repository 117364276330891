import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "../SectionDateRange";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Amenities_demos, PHOTOS } from "./constant";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import html2pdf from 'html2pdf.js';
import GuestsInput from "./GuestsInput";
import { SavePackageUser, calculatePackagePrice, getCityHotelMealPlans, getPackageQuotationById, getPackageQuotationList, getPackageUserFavoritePriceList, removePackageFavoriteFromHeader, savePackageFavoriteFromHeader, updateMarginAndDescription, updateUserFavorite } from "../../../services/services/globalmaster/packageServices";
import { applicationName, currencyIcon, url } from "services/services/common/const";
import PackageDetailsCity from "containers/PackageDetailsCity/PackageDetailsCity";
import PackageTransferAndlocation from "components/PackageTransferAndLocation/PackageTransferAndLocation";
import FormItem from "containers/PageAddListing1/FormItem";
import { GetTransportRateList } from "services/services/globalmaster/transportRateServices";
import { GetPickupDropList } from "services/services/globalmaster/pickupAndDropService";
import Label from "components/Label/Label";
import { useDispatch, useSelector } from "react-redux";
import { getPackageReviewsList, savePackageReviews } from "services/services/packageReviews/packageReviewsServices";
import convertNumbThousand from "utils/convertNumbThousand";
import Checkbox from "shared/Checkbox/Checkbox";
import HeaderFilter from "containers/PageHome/HeaderFilter";
import PackageQuotationTab from "./PackageQuotationTab";
import Textarea from "shared/Textarea/Textarea";
import ReactToPrint from "react-to-print";
import PackagePdfCommon from "components/pdfpackageQuotation/PackagePdfCommon";
import Select from "shared/Select/Select";
import RequiredMessage from "components/common/RequiredMessage";
import { errornotify, successnotify } from "components/common/Notification";
import InternationalPackageCIty from "containers/PackageDetailsCity/InternationalPackageCIty";
import { Helmet } from "react-helmet";
import { packageDetailsByPackageId } from "store/action";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
const PackageQuotation: FC<{}> = () => {
  const { packageId } = useParams<any | string>();
  const [userName, setUserName] = useState(
    undefined || "manisha@aahvan.com"
  );
  const [packageDetails, setPackages] = useState<any | undefined>(undefined);
  const [isRequiredField, setIsRequiredField] = useState(false);
  const [listNumberOfRooms, setListNumberOfRooms] = useState([]);
  const [pkgCityHotelMealPlans, setpkgCityHotelMealPlans] = useState([]);
  const [transportRateList, setTransportRateList] = useState([]);
  const [pickupAndDropList, setPickupAndDropList] = useState([]);
  const [totalNights, setTotalNights] = useState(0);
  const [showMargin, setShowMargin] = useState(false)
  const [likedState, setLikedState] = useState(false)
  const [defaultFaorite, setDefaultFavorite] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const [searchParams] = useSearchParams();
  const packageQuotationId: any = searchParams.get("packageQuotationId");
  const userFavorite: any = searchParams.get("userFavorite");
  const [byDefaultDetails, setByDefaultDetails] = useState({
    is_active: true,
    row_created_date: new Date(),
    row_created_by: userName,
    row_altered_date: new Date(),
    row_altered_by: userName,
  });
  const {
    is_active,
    row_created_by,
    row_created_date,
    row_altered_by,
    row_altered_date,
  } = byDefaultDetails;

  const [inputList, setInputList] = useState({
    hoteloption: null,
    mealplans: null,
    cabtransfer:
      packageDetails?.transport_id ||
      packageDetails?.selectedTransport?.transport_id,
    pickuplocation: packageDetails?.pickup_location_id,
    droplocation: packageDetails?.drop_location_id,
    travelDate: new Date() as any,
    numberofRooms: packageDetails?.number_of_rooms || 1,
    numberofCabs: packageDetails?.number_of_cabs || 1,
    numberofpersons: packageDetails?.number_of_persons || 2,
    children: packageDetails?.number_of_childrens || 0,
    kids: packageDetails?.number_of_kids || 0,
    leadPasanger: packageDetails?.lead_pasanger_name,
    phone: packageDetails?.phone,
    email: packageDetails?.email,
    companyName: packageDetails?.company_name,
    flightbooked: false,
    marginPrice: packageDetails?.margin || 0,
    marginDescription: packageDetails?.marginDescription || "",
    originalPrice: packageDetails?.package_price || 0,
    totalPrice: 0,
    numberOfHotels: 0,
    quotationId: packageDetails?.quotation_id,
  });
  const {
    email,
    phone,
    cabtransfer,
    children,
    companyName,
    droplocation,
    pickuplocation,
    flightbooked,
    kids,
    leadPasanger,
    marginDescription,
    marginPrice,
    numberOfHotels,
    numberofCabs,
    numberofRooms,
    numberofpersons,
    originalPrice,
    travelDate,
    quotationId,
    totalPrice
  } = inputList;
  const date = new Date(travelDate);
  // Manisha - Change 1 ---------------------
  const [totalHotel, setTotalHotel] = useState(0);
  const [tempackageQuotationId, setPackageQuotaionId] = useState(packageQuotationId)
  const [totalActivities, setTotalActivities] = useState(0);
  console.log(packageDetails)
  // M---------------------
  const addRemoveDefaultFavorite = async (isLiked: any) => {
    try {
      if (isLiked) {
        const addFavorite: any = await savePackageFavoriteFromHeader({
          Destination_type_id: packageDetails?.destination_type_id,
          Destination_id: packageDetails?.destination_id,
          Package_id: packageDetails?.package_id,
          Package_calculate_price: packageDetails?.package_price,
          Row_created_by: row_created_by,
        })

        if (addFavorite?.status === "SUCCESS") {
          setPackageQuotaionId(parseInt(addFavorite?.data[1]))
          setLikedState(isLiked)
          successnotify("Added to favorite successfully !")
          navigate(
            `/packageDetails/${packageId}?packageQuotationId=${parseInt(tempackageQuotationId)}&userFavorite=${true}`
          )
        }
        else {
          throw addFavorite?.message;
        }
      } else {
        const removeFavorite: any = await removePackageFavoriteFromHeader({
          Destination_type_id: packageDetails?.destination_type_id,
          Destination_id: packageDetails?.destination_id,
          Package_id: packageDetails?.package_id,
          Package_quotation_id: parseInt(tempackageQuotationId),
          Row_created_by: row_created_by,
        })
        if (removeFavorite?.status === "SUCCESS") {
          setLikedState(isLiked)
          navigate(
            `/packageDetails/${packageId}?packageQuotationId=${parseInt(tempackageQuotationId)}&userFavorite=${false}`
          )
          successnotify("Remove from favorite successfully !")
        }
        else {
          throw removeFavorite?.message;
        }
      }
    } catch (error) {
      errornotify(error)
    }
  }
  useEffect(() => {
    if (packageDetails) {
      let nights = 0;

      // Manisha - Change 2 ---------------------
      let hotels = 0;
      let activities = 0;
      //------------------------

      packageDetails?.packageCityList?.map((item: any, index: any) => {
        nights += item.packageCityHotelList[0]?.nights;

        item?.packageCityHotelList.map((itemHotel: any, index: any) => {
          hotels++; // Manisha - Change 2 ---------------------
        });
        item?.siteSeeingList
          ?.filter((itemSite: any, index: any) => {
            return itemSite?.is_selected;
          })
          .map((siteSeeing: any, index: any) => {
            alert(siteSeeing);
            activities++; // Manisha - Change 2 ---------------------
          });

        // item.hotel_id = item?.selectedHotelMealPlan?.hotel_id;
        // item.meal_plan_code = item?.selectedHotelMealPlan?.meal_plan_code;
        // /**selected site seeing set */
        // item?.siteSeeingList
        //   ?.filter((itemSite, index) => {
        //     return itemSite?.is_selected;
        //   })
        //   .map((siteSeeing, index) => {
        //     item?.selectedSiteSeeingList.push({
        //       City_site_seeing_id: siteSeeing?.city_site_seeing_id,
        //       Package_quotation_siteseeing_id:
        //         siteSeeing?.package_quotation_siteseeing_id,
        //       Site: siteSeeing?.site,
        //       key: siteSeeing?.key,
        //       Is_active: is_active,
        //       Row_created_by: row_created_by,
        //       Row_created_date: row_created_date,
        //       Row_altered_by: row_altered_by,
        //       Row_altered_date: row_altered_date,
        //     });
        //   });
      });

      setTotalNights(nights);

      //Manisha - Change 2 ---------------------
      setTotalHotel(hotels);
      setTotalActivities(activities);
      //-----------------------------------------

      // setUpdatedCityAndNights([...packageDetails?.packageCityList]);
    }
  }, [packageDetails]);
  console.log("packageDetails", packageDetails)

  /** This is for set number of rooms*/
  useEffect(() => {
    let tempListNumberofRooms: any = [];
    if (numberofpersons + children > 0) {
      for (
        let index = Math.ceil(
          (parseInt(numberofpersons) + parseInt(children)) / 3
        );
        index <=
        Math.ceil((parseInt(numberofpersons) + parseInt(children)) / 2);
        index++
      ) {
        tempListNumberofRooms.push(index);
      }
    }
    setListNumberOfRooms(tempListNumberofRooms);
  }, [numberofpersons, children]);

  /**This is inputlist onchangehandller */
  const onChangeHandller = async (e: any, packageCityId: any) => {
    setLikedState(false)
    setDefaultFavorite(false)
    if (e.target.name === "hotel_id" || e.target.name === "meal_plan_code") {
      let updatedArray = packageDetails?.packageCityList?.map((item: any, index: any) => {
        if (item?.package_city_id === packageCityId) {
          if (e.target.name === "hotel_id") {
            item.packageCityHotelList[0].hotel.selectedHotelMealPlan.hotel_id =
              parseInt(e.target.value);
          }
          if (e.target.name === "hotel_id") {
            item.packageCityHotelList[0].hotel.selectedHotelMealPlan.meal_plan_code = e.target.value;
          } else {
            item.packageCityHotelList[0].hotel.selectedHotelMealPlan.meal_plan_code =
              e.target.value;
          }

          // e.target.name === "hotel_id"
          //   ? (item.selectedHotelMealPlan.hotel_id = parseInt(e.target.value))
          //   : (item.selectedHotelMealPlan.meal_plan_code = e.target.value);
        }
      });
      // setUpdatedCityAndNights([...packageDetails?.packageCityList]);
    } else {
      if (e.target.name === "flightbooked") {
        setInputList({ ...inputList, [e.target.name]: e.target.checked });
      }
      else if (e.target.name === "travelDate") {
        const travelDate = new Date(e.target.value)
        setInputList({ ...inputList, [e.target.name]: travelDate });
      }
      else {
        setInputList({ ...inputList, [e.target.name]: e.target.value });
      }
    }
  };

  /***print page style */
  const marginTop = "30px";
  const marginRight = "30px";
  const marginBottom = "30px";
  const marginLeft = "30px";
  const getPageMargins = () => {
    return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important;
  }`;
  };
  /**this is for remove favorite package */
  const removeFavorite = async () => {
    try {
      const packageQuotation = {
        Package_quotation_id: parseInt(packageQuotationId),
        Is_favourite: false,
        package_id: packageDetails.package_id,
        Row_created_by: row_created_by,
      };
      const saveResult = await updateUserFavorite(packageQuotation);
      if (saveResult?.status === "SUCCESS") {
        navigate(
          `/packageDetails/${packageId}?packageQuotationId=${parseInt(packageQuotationId)}&userFavorite=${false}`
        );
        setLikedState(false)
        successnotify("Removed from favorites successfully")
      }
      else {
        throw saveResult?.message;
      }


    } catch (error) {
      errornotify(error)
    }

  }


  /**This is for international package city */
  const internationalPkgCity = useSelector(
    (state: any) => state.selectOptionFilterReducer.itrCityDetails
  )
  console.log("internationalPkgCity", internationalPkgCity)
  /**This is for package save quotation and favorite*/

  const savePackageQuotation = async (isFavorite: boolean, marginAdd?: boolean) => {
    try {
      let cityDetails: any = [];
      if (!isFavorite) {
        setIsRequiredField(true)
        if (!cabtransfer || !pickuplocation || !droplocation || !travelDate || !numberofRooms || !numberofCabs || !numberofpersons || !leadPasanger || !phone || !email) {
          errornotify("Some fields are required!")
          return;
        }
      }


      if (packageDetails?.destination_type_id === 1) {
        packageDetails?.packageCityList?.map((item: any, index: any) => {
          const selectedHotelId =
            item?.packageCityHotelList[0]?.hotel?.selectedHotelMealPlan?.hotel_id;
          const selectedMealPlan =
            item?.packageCityHotelList[0]?.hotel?.selectedHotelMealPlan
              ?.meal_plan_code;
          if (!selectedHotelId || !selectedMealPlan) {
            // setShowErrorNotification(true);
            return "Please select hotel.";
          }
          // if (!selectedMealPlan) {
          //   // setShowErrorNotification(true);
          //   throw "Please select meal plan.";
          // }
          cityDetails.push({
            ...item,
            Package_city_id: item?.package_city_id,
            SiteSeeingList: item?.selectedSiteSeeingList,
            City_id: item?.city_id,
            City_nights_id: item.city_nights_id,
            Hotel_id: selectedHotelId,
            Meal_plan_code: selectedMealPlan,
            Nights: item?.packageCityHotelList[0]?.nights,
            Is_active: is_active,
            Row_created_by: row_created_by,


            Row_created_date: row_created_date,
            Row_altered_by: row_altered_by,
            Row_altered_date: row_altered_date,
          });
        });
      }

      // setPackageQuotationId(null);
      const PackageQuotation = {
        Package_quotation_id: 0,
        Package_id: packageDetails?.package_id,
        PackageCityList: packageDetails?.destination_type_id === 1 ? cityDetails : [internationalPkgCity], /**This city set condition according to domestic or international */
        Transport_id: parseInt(cabtransfer),
        Transport_rate_id: packageDetails?.transport_rate_id,
        Pickup_location_id: pickuplocation ? parseInt(pickuplocation) : 0,
        Drop_location_id: droplocation ? parseInt(droplocation) : 0,
        Travel_date: new Date(travelDate),
        Is_favourite: isFavorite,
        Margin: parseFloat(marginPrice),
        MarginDescription: marginDescription,
        Number_of_rooms: parseInt(numberofRooms),
        Number_of_cabs: parseInt(numberofCabs),
        Number_of_persons: parseInt(numberofpersons),
        Number_of_childrens: parseInt(children),
        Number_of_kids: parseInt(kids),
        Lead_pasanger_name: leadPasanger,
        Phone: phone,
        Email: email,
        Company_name: companyName,
        Flight_booked: flightbooked,
        Is_active: is_active,
        Row_created_by: row_created_by,
        Row_created_date: row_created_date,
        Row_altered_by: row_altered_by,
        Row_altered_date: row_altered_date,
      };
      /**this is for calculate price */

      debugger;
      const packagePriceResult = await calculatePackagePrice(PackageQuotation);
      if (packagePriceResult?.status === "SUCCESS") {
        let packagePriceOriginal = parseInt(packagePriceResult.data[1]);
        // setTotalPrice(packagePriceOriginal + marginPrice);
        setInputList({
          ...inputList,
          originalPrice: packagePriceOriginal,
          totalPrice: packagePriceOriginal + parseFloat(marginPrice),
        });
        const saveResult = await SavePackageUser({
          ...PackageQuotation,
          Package_calculate_price: packagePriceOriginal,
        });

        // setUpdatableCheckPriceList(PackageQuotation);
        if (saveResult?.status === "SUCCESS") {
          setIsRequiredField(false)
          // navigate(
          //   `/packageDetails/${packageId}?packageQuotationId=${parseInt(saveResult.data[1])}`
          // );
          // getData()
          if (isFavorite) {
            successnotify("Added to favorites successfully !")
            navigate(
              `/packageDetails/${packageId}?packageQuotationId=${parseInt(saveResult.data[1])}&userFavorite=${true}`
            );
            // Add: Added to favorites successfully
            // Remove: Removed from favorites successfully
            setLikedState(true)
          }
          else if (marginAdd) {
            successnotify("Margin added successfully !")
          }
          else {
            successnotify("Latest price calculated successfully!")
          }

          setInputList({
            ...inputList,
            originalPrice: packagePriceOriginal,
            totalPrice: packagePriceOriginal + parseFloat(marginPrice),
          });
        } else {
          throw (saveResult?.message);
        }
      } else {
        throw (packagePriceResult?.message);

      }
    } catch (error) {
      console.log("error", error)
      errornotify(error)
    } finally {
      // setIsWaiting({ ...isWaiting, isWaitingCheckPrice: false });
    }
  };

  /** update margin */
  const updateMarginHandller = async () => {
    try {
      const packageMargintAndDescription = {
        Row_created_by: row_created_by,
        Package_id: packageDetails?.package_id,
        Package_quotation_id: parseInt(packageQuotationId),
        Margin: parseFloat(marginPrice),
        MarginDescription: marginDescription,
      };
      const saveResult = await updateMarginAndDescription(
        packageMargintAndDescription
      );
      if (saveResult?.status === "SUCCESS") {
        setInputList({ ...inputList, totalPrice: (originalPrice + parseFloat(marginPrice)) })
        successnotify("Margin updated successfully !")
        // getData()
        //SUCCESSNOTIFY
      }
      else {
        throw saveResult?.message
      }
    } catch (error) {
      errornotify(error)
    } finally {
      // setIsWaiting({ ...isWaiting, isWaitingSaveMargin: false });
    }

  }
  /**add update remove margin handller */
  const addUpdateMarginHandller = () => {
    if (packageQuotationId) {
      updateMarginHandller()
    }
    else {
      savePackageQuotation(false, true)
    }

  }
  const addMarginHandller = () => {
    setShowMargin(true);
  }
  /**remove margin handller */
  const removeMarginHandller = async () => {
    if (packageQuotationId) {
      try {
        const packageMargintAndDescription = {
          Row_created_by: row_created_by,
          Package_id: packageDetails?.package_id,
          Package_quotation_id: parseInt(packageQuotationId),
          // Margin: 0,
          // MarginDescription: "",
        };
        const saveResult = await updateMarginAndDescription(
          packageMargintAndDescription
        );
        if (saveResult?.status === "SUCCESS") {
          setInputList({ ...inputList, marginDescription: "", marginPrice: 0 })
          setShowMargin(false);
          successnotify("margin removed successfully !")
          // getData()
        }
        else {
          throw saveResult?.message
        }
      } catch (error) {
        errornotify(error);
      } finally {

      }
    }
    else {
      successnotify("margin removed successfully !")
      setInputList({ ...inputList, marginDescription: "", marginPrice: 0 })
      setShowMargin(false)
    }
  }
  /**This is for handller site seeing */
  const onChangeHandllerSiteSeeing = (
    e: any,
    key: any,
    packageCityId: any,
    package_quotation_siteseeing_id: any
  ) => {
    setLikedState(false)
    setDefaultFavorite(false)
    let newArray = packageDetails?.packageCityList?.map((itemCity: any, index: any) => {
      if (itemCity.package_city_id === packageCityId) {
        const findIndex = itemCity.selectedSiteSeeingList
          ?.map(function (item: any) {
            return item.key;
          })
          .indexOf(key);
        if (findIndex > -1) {
          itemCity?.selectedSiteSeeingList?.splice(findIndex, 1);
        } else {
          itemCity?.selectedSiteSeeingList.push({
            Package_quotation_siteseeing_id: package_quotation_siteseeing_id,
            City_site_seeing_id: parseInt(e.target.value),
            Site: e.target.name,
            key: key,
            Is_active: is_active,
            Row_created_by: row_created_by,
            Row_created_date: row_created_date,
            Row_altered_by: row_altered_by,
            Row_altered_date: row_altered_date,
          });
        }
      }
    });
  };
  /**This function for get packageDetails */
  const getData = async () => {
    try {
      if (packageId) {
        let params = new URLSearchParams();
        params.append("UserId", userName);
        params.append("package_id", packageId)
        let pkgResponse = [];
        if (packageQuotationId && userFavorite === "true") {
          params.append("package_quotation_id", packageQuotationId)
          pkgResponse = await getPackageUserFavoritePriceList(params)
        }
        else if (packageQuotationId) {
          params.append("package_quotation_id", packageQuotationId)
          pkgResponse = await getPackageQuotationById(params)
        }

        else {
          pkgResponse = await getPackageQuotationList(params)
        }

        if (pkgResponse?.data?.status === "SUCCESS") {
          const packageCityHotelResult = await getCityHotelMealPlans(params);
          if (packageCityHotelResult?.data?.status === "SUCCESS") {
            setpkgCityHotelMealPlans(packageCityHotelResult?.data?.data);
            const transportRateResult = await GetTransportRateList();
            if (transportRateResult?.data?.status === "SUCCESS") {
              setTransportRateList(transportRateResult.data.data);
            } else {
              throw transportRateResult?.message;
            }
            const pickupAndDropResult = await GetPickupDropList();
            if (pickupAndDropResult?.data?.status === "SUCCESS") {
              setPickupAndDropList(pickupAndDropResult.data.data);
            } else {
              throw pickupAndDropResult?.message;

            }
          }
          else {
            throw packageCityHotelResult?.message;
          }
          let allPackages = pkgResponse?.data?.data;
          const activePackages = allPackages?.filter((packageDetails: any) => {
            return packageDetails?.is_active
          }
          );
          let packageQuotation: any = {};
          if (activePackages?.length > 0) {

            // setTotalPackages(activePackages?.length)
            setPackages(activePackages[0])
            dispatch(packageDetailsByPackageId(activePackages[0]))
            packageQuotation = activePackages[0];
            setLikedState(packageQuotation?.is_favourite)
            setInputList({
              ...inputList, cabtransfer: packageQuotation?.transport_id || packageQuotation?.selectedTransport
                .transport_id, companyName: packageQuotation?.company_name, children: packageQuotation?.number_of_childrens || 0, email: packageQuotation?.email, droplocation: packageQuotation?.drop_location_id, flightbooked: packageQuotation?.flight_booked, kids: packageQuotation?.number_of_kids || 0, phone: packageQuotation?.phone, leadPasanger: packageQuotation?.lead_pasanger_name, marginDescription: packageQuotation?.marginDescription || "", marginPrice: packageQuotation?.margin || 0, travelDate: packageQuotation?.travel_date === "0001-01-01T00:00:00" ? new Date() : new Date(packageQuotation?.travel_date), numberofCabs: packageQuotation?.number_of_cabs || 1, pickuplocation: packageQuotation?.pickup_location_id, numberofRooms: packageQuotation?.number_of_rooms || 1, originalPrice: packageQuotation?.package_price || 0, numberofpersons: packageQuotation?.number_of_persons || 2, quotationId: packageQuotation?.quotation_id,

              totalPrice: ((packageQuotation?.package_price || 0) + (packageQuotation?.margin || 0))
            })



          }
        }
        else {
          throw pkgResponse?.message;
        }
      }
    } catch (error) {
      errornotify(error)
    }
  }

  // console.log(packageDetails)
  useEffect(() => {
    getData()
  }, [packageId])
  //

  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const thisPathname = useLocation().pathname;
  const router = useNavigate();

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <div>
            {packageDetails?.is_best_selling && <Badge name={
              <div className="flex items-center">
                <i className="text-sm las la-star"></i>
                <span className="ml-1">Best Selling</span>
              </div>
            } />}
            {packageDetails?.is_family_package && <Badge className="relative ml-2" name={
              <div className="flex items-center">
                <i className="text-sm las la-user-friends"></i>
                <span className="ml-1">Family</span>
              </div>
            } color="yellow" />}
            {packageDetails?.is_honeymoon_package && <Badge className="relative ml-2" name={
              <div className="flex items-center">
                <i className="text-sm lab la-gratipay"></i>
                <span className="ml-1">Honeymoon</span>
              </div>
            }
              color="green" />}
          </div>
          <LikeSaveBtns favoriteBtnName={likedState ? "Remove from favorite" : "Save to Favorite"} likedState={likedState} addFavorite={() => defaultFaorite ? addRemoveDefaultFavorite(true) : savePackageQuotation(true)} removeFavorite={() => defaultFaorite ? addRemoveDefaultFavorite(false) : removeFavorite()} />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {packageDetails?.package_name}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <StartRating point={packageDetails?.package_reviews_rating_average} reviewCount={packageDetails?.package_reviews_rating_count} />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {packageDetails?.destination_name}</span>
          </span>
        </div>

        {/* 4 */}
        {/* <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              Kevin Francis
            </span>
          </span>
        </div> */}

        {/* 5 */}
        {/* <div className="w-full border-b border-neutral-100 dark:border-neutral-700" /> */}

        {/* 6 */}
        {/* <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              6 <span className="hidden sm:inline-block">guests</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              6 <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              3 <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              2 <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div>
        </div> */}
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Overview</h2>
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div> */}
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            {packageDetails?.package_description}
          </span>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` About the property's amenities and services`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {Amenities_demos.filter((_, i) => i < 12).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View more 20 amenities
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">

          <FormItem label="Travel Date" isRequired={true}>

            <Input type="date" name="travelDate" value={`${travelDate?.getFullYear()}-${(travelDate?.getMonth() + 1).toString().padStart(2, '0')}-${travelDate?.getDate().toString().padStart(2, '0')}`} onChange={(e) => onChangeHandller(e, undefined)} />
            {!travelDate && isRequiredField ? <RequiredMessage message="Travel Date is required" /> : ""}
          </FormItem>
          <FormItem label="Number of Cabs" isRequired={true}>
            <Input value={numberofCabs} name="numberofCabs" defaultValue={numberofCabs} typeof="number" onChange={(e) => onChangeHandller(e, undefined)} />
            {!numberofCabs && isRequiredField ? <RequiredMessage message="Number of Cabs is required" /> : ""}
          </FormItem>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
          <FormItem label="Lead Passenger Name" isRequired={true}>
            <Input value={leadPasanger} name="leadPasanger" defaultValue={leadPasanger} onChange={(e) => onChangeHandller(e, undefined)} />
            {!leadPasanger && isRequiredField ? <RequiredMessage message="Lead Passenger Name is required" /> : ""}
          </FormItem>
          <FormItem label="Number of Persons" isRequired={true}>
            <Input value={numberofpersons} name="numberofpersons" defaultValue={numberofpersons} typeof="number" onChange={(e) => onChangeHandller(e, undefined)} />
            {!numberofCabs && isRequiredField ? <RequiredMessage message="Number of Cabs is required" /> : ""}
          </FormItem>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
          <FormItem label="Email" isRequired={true}>
            <> <Input value={email} defaultValue={email} name="email" typeof="email" onChange={(e) => onChangeHandller(e, undefined)} />
              {!email && isRequiredField ? <RequiredMessage message="Email is required" /> : ""}
            </>
          </FormItem>
          <FormItem label="Number of Children (7 to 17 years)">
            <Input value={children} defaultValue={children} name="children" onChange={(e) => onChangeHandller(e, undefined)} />
          </FormItem>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
          <FormItem label="Phone" isRequired={true}>
            <Input value={phone} defaultValue={phone} name="phone" type="number" onChange={(e) => onChangeHandller(e, undefined)} />
            {!phone && isRequiredField ? <RequiredMessage message="Phone is required" /> : ""}
          </FormItem>
          <FormItem label="Number of Kids (0 to 6 years)">
            <Input value={kids} defaultValue={kids} name="kids" type="number" onChange={(e) => onChangeHandller(e, undefined)} />
          </FormItem>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
          <FormItem label="Company Name" isRequired={true}>
            <Input value={companyName} defaultValue={companyName} name="companyName" onChange={(e) => onChangeHandller(e, undefined)} />
            {!companyName && isRequiredField ? <RequiredMessage message="Company Name is required" /> : ""}
          </FormItem>
          {/* <FormItem label="Number of Rooms"> */}
          {/* <Input value={numberofRooms} defaultValue={numberofRooms} name="numberofRooms" onChange={(e) => onChangeHandller(e, undefined)} /> */}
          <FormItem
            label="Number of Rooms"
            isRequired={true}
          >
            <Select id={`number_of_rooms`}
              defaultValue={numberofRooms}
              name="numberofRooms"
              onChange={(e) => onChangeHandller(e, undefined)}>
              <option>Select rooms</option>
              {listNumberOfRooms?.map(
                (item: any, index: any) => {
                  return (
                    <option
                      value={item}
                      key={index}
                      selected={
                        numberofRooms === item
                      }
                    >
                      {item}
                    </option>
                  );
                }
              )}
            </Select>
            {!numberofRooms && isRequiredField ? <RequiredMessage message="Number of Rooms" /> : ""}
          </FormItem>
        </div>
        <div>
          {/* <div className="flex mt-3 "> */}
          <div className="flex items-center mt-2">
            <Checkbox
              defaultChecked={flightbooked}
              id={"yes"}
              name={"flightbooked"}
              onChange={(e: any) => onChangeHandller(e, undefined)}
              label="Flight Already Booked ?"
            />
            {/* </div> */}
            {/* <div className="flex items-center mt-2 ml-5">
              <input
                defaultChecked={!flightbooked}
                id={"no"}
                name={"flightbooked"}
                onChange={(e: any) => onChangeHandller(e, undefined)}
                type="radio"
                className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
              />
              <label
                htmlFor={"no"}
                className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
              >
                No
              </label>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="/author">See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  /**This is for star rate */
  const pkgReviewRate = useSelector(
    (state: any) => state.selectOptionFilterReducer.pkgReviewRate
  )
  const [reviewsDetails, setReviewsDetails] = useState({
    reviews: undefined,
    starRate: pkgReviewRate,
    reviewsList: [{}],
    showReviews: 3,
  });
  console.log(pkgReviewRate)
  const { reviews, starRate, reviewsList, showReviews } = reviewsDetails;
  /**onchange handller */
  const onChangeReviewHandller = (e: any) => {
    setReviewsDetails({ ...reviewsDetails, reviews: e.target.value });
  };
  /*This function for get reviews list */
  const getReviewList = async () => {
    try {
      let params: any = new URLSearchParams();
      params.append("Package_id", packageId)
      const reviewResponse = await getPackageReviewsList(params);
      if (reviewResponse?.data?.status === "SUCCESS") {
        setReviewsDetails({ ...reviewsDetails, reviewsList: reviewResponse?.data?.data })
      }
      else {
        throw reviewResponse?.message;
      }
    } catch (error) {
      errornotify(error)
    }

    // setReviewsDetails({...reviewsDetails,reviewsList:})
  }
  useEffect(() => {
    if (packageId) {
      getReviewList()
    }
  }, [packageId])

  /**This function for save package reviews */
  const saveHandller = async (e: any) => {
    // e.prevenDefault();
    try {
      const savedData = {
        Package_id: parseFloat(packageDetails?.package_id),
        Reviews: reviews,
        Reviews_rating: pkgReviewRate,
        Row_created_by: userName,
        Row_created_date: new Date(),
      };
      const response: any = await savePackageReviews(savedData);
      if (response?.status === "SUCCESS") {
        successnotify("Your review added.")
        getReviewList()
        // console.log(response?.message)
      }
      else {
        throw response?.message;
      }
    } catch (error) {
      errornotify(error);
    }
  };
  const showReviewsHandller = () => {
    setReviewsDetails({ ...reviewsDetails, showReviews: reviewsList?.length })
  }
  const showlessReviewsHandller = () => {
    setReviewsDetails({ ...reviewsDetails, showReviews: 3 })
  }
  const renderSection6 = () => {

    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews ({reviewsList?.length} reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" defaultPoint={starRate} />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              defaultValue={reviews}
              value={reviews}
              onChange={onChangeReviewHandller}
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
              onClick={saveHandller}
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          {
            reviewsList?.filter((_: any, index: any) => {
              return index < showReviews;
            })?.map((item: any, index: any) => {
              return <CommentListing data={item} className="py-8" />
            })
          }
          <div className="pt-8">
            {showReviews < reviewsList?.length ? <ButtonSecondary onClick={showReviewsHandller}>View more reviews</ButtonSecondary> : <ButtonSecondary onClick={showlessReviewsHandller}>View less reviews</ButtonSecondary>}
          </div>
        </div>
      </div>
    );
  };

  const pdfPreview = () => {
    return (<div ref={componentRef}>
      <PackagePdfCommon
        companyName={companyName}
        destinationName={
          packageDetails?.destination_name
        }
        email={email}
        leadName={leadPasanger}
        mobileNumber={phone}
        pax={`Adult ${numberofpersons} Child ${children} kids ${kids}`}
        proposalId={quotationId}
        travelDate={`${date.getDate()}/${date.getMonth() + 1
          }/${date.getFullYear()}`}
        inclusions={packageDetails?.inclusionsList}
        totalPax={
          parseInt(numberofpersons) +
          parseInt(children) +
          parseInt(kids)
        }
        totalPerson={
          parseInt(numberofpersons) +
          parseInt(children) +
          parseInt(kids)
        }
        // totalPrice={totalPrice}
        totalPrice={totalPrice}
        totalTransfers={1}
        numberOfNights={totalNights} //Added by Manisha
        totalHotel={totalHotel} //Added by Manisha
        totalActivities={totalActivities} //Added by Manisha
        cityHotelList={
          packageDetails?.packageCityList
        } //Added by Manisha
        packageGuideLines={
          packageDetails?.packageGuideLines
        } //Added by Manisha
        packageItineraryList={
          packageDetails?.package_itinerary_list
        } //Added by Manisha

      //Below code is not worki
      // cityHotelList = {[...packageDetails?.packageCityList,...packageDetails?.packageCityList.map((e)=>e.packageCityHotelList).flat()]}
      />
    </div>)
  }
  const renderTravelPlan = () => {
    return (
      <div className="listingSection__wrap lg:shadow-xl">
        <span className="text-2xl font-semibold block">
          Travel plan
        </span>
        <div className="mt-8 flex">
          <div className="flex-shrink-0 flex flex-col items-center py-2">
            {packageDetails?.package_itinerary_list?.map((itinerary: any, index: any) => {
              return <>  <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                {index < packageDetails?.package_itinerary_list?.length - 1 ? <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span> : ''}
              </>
            })
            }
            {/* <span className="block w-6 h-6 rounded-full border border-neutral-400"></span> */}
          </div>
          <div className="ml-4 space-y-14 text-sm">
            {packageDetails?.package_itinerary_list?.map((itinerary: any, index: any) => {
              return <div className="flex flex-col space-y-2">
                <span className=" font-semibold">
                  DAY {itinerary?.day} : {itinerary?.itinerary_title}
                </span>
                <span className=" text-neutral-500 dark:text-neutral-400">
                  10.00 AM to 10.00 PM
                </span>
                <span className="text-neutral-500 dark:text-neutral-400">
                  {itinerary?.itinerary_description}
                </span>
              </div>
            })}

          </div>
        </div>
      </div>
    );
  };
  const InclusionSection = (isInclude: boolean) => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">{isInclude ? "Inclusions" : "Exclusions"}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {isInclude ? "Include" : "Exclude"} in the price
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-1 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {packageDetails?.inclusionsList?.filter((incl: any, i: number) => isInclude ? incl.is_include : !incl.is_include)
            .map((item: any, i: number) => (
              <div key={i} className="flex items-center space-x-3">
                <i className={`las la-${isInclude ? "check" : "times"}-circle text-2xl`}></i>
                <span>{item?.inclusions}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            San Diego, CA, United States of America (SAN-San Diego Intl.)
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY&q=Eiffel+Tower,Paris+France"
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Refund 50% of the booking value when customers cancel the room
            within 48 hours after successful booking and 14 days before the
            check-in time. <br />
            Then, cancel the room 14 days before the check-in time, get a 50%
            refund of the total amount paid (minus the service fee).
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>08:00 am - 12:00 am</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>02:00 pm - 04:00 pm</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            {currencyIcon}{convertNumbThousand(totalPrice)}
            {/* <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /night
            </span> */}
          </span>
          <StartRating point={packageDetails?.package_reviews_rating_average} reviewCount={packageDetails?.package_reviews_rating_count} />
        </div>
        <div className="flex items-center space-x-8 mt-3">
          <ButtonPrimary onClick={() => savePackageQuotation(false)}>Check Price</ButtonPrimary>
          <ButtonSecondary onClick={() => showMargin ? removeMarginHandller() : addMarginHandller()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              />
            </svg>

            <span className="ml-3" >{showMargin ? "Remove " : "Add Margin"}</span>


          </ButtonSecondary>

        </div>

        {showMargin ? <div>
          <FormItem label="Margin">
            <Input type="number" name="marginPrice" value={marginPrice} defaultValue={marginPrice} onChange={(e) => onChangeHandller(e, undefined)} />

          </FormItem>
          <FormItem label="Description">
            <Textarea placeholder="description..." rows={2} onChange={(e) => onChangeHandller(e, undefined)} defaultValue={marginDescription} value={marginDescription} name="marginDescription" />
            {/* <Input value={marginDescription} name="marginDescription" defaultValue={marginDescription} typeof="text" onChange={(e) => onChangeHandller(e, undefined)} /> */}
          </FormItem>
          <div className="mt-3">
            <ButtonPrimary onClick={addUpdateMarginHandller}>Save margin</ButtonPrimary>
          </div>
        </div> : ""}

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Actual Cost of the Tour</span>
            <span>{currencyIcon}{convertNumbThousand(packageDetails?.package_price)}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Margin</span>
            <span>{currencyIcon}{convertNumbThousand(marginPrice)}</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>{currencyIcon}{convertNumbThousand(totalPrice)}</span>
          </div>
        </div>

        {/* SUBMIT */}
        <ReactToPrint
          trigger={() => (

            <ButtonPrimary >Download PDF</ButtonPrimary>
          )}
          content={() => componentRef.current}
          onBeforePrint={() => {
            const content = componentRef.current;

            if (content) {
              const opt = {
                margin: 10,
                filename: 'generated.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
              };

              html2pdf().from(content).set(opt).outputPdf();
            }
          }}
          pageStyle={getPageMargins}
        />
      </div>
    );
  };
  const tabs = ["Information", "Travel Plan", "Inclusions & Exclusions"]
  const [activeTab, setActiveTab] = useState("Information")
  const handleClickTab = (item: any) => {
    setActiveTab(item)
  }

  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
            onClick={handleOpenModalImageGallery}
          >
            {packageDetails?.imageList?.filter((itemImage: any, index: number) => {
              return itemImage?.is_primary;
            })?.map((itemImg: any, index: number) => {
              return <img
                className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                src={`${url}/Images/package/${packageDetails?.package_id}/${itemImg?.image_name}`}
                alt=""
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
              />
            })}
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {packageDetails?.imageList?.filter((itemImg: any, i: any) => {
            return !itemImg?.is_primary && i < 5
          }
          ).map((item: any, index: any) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                }`}
            >
              <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                <img
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={`${url}/Images/package/${packageDetails?.package_id}/${item?.image_name}`}
                  alt=""
                  sizes="400px"
                />
              </div>

              {/* OVERLAY */}
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={handleOpenModalImageGallery}
              />
            </div>
          ))}

          <button
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="w-5 h-5" />
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Show all photos
            </span>
          </button>
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          <PackageQuotationTab tabActive={activeTab}
            tabs={tabs}
            onClickTab={handleClickTab} />
          {activeTab === "Information" ? <>
            {renderSection2()}
            {console.log("packageDetails", packageDetails)}
            {
              packageDetails?.destination_type_id === 1 ?
                packageDetails?.packageCityList?.map((itemCity: any, index: any) => {
                  return <PackageDetailsCity isRequiredField={isRequiredField} index={index} item={itemCity} pkgCityHOtelmeal={pkgCityHotelMealPlans} onChangeEventHandller={onChangeHandller} onChangeHandllerSiteSeeing={onChangeHandllerSiteSeeing} />
                }) : packageDetails?.packageCityList?.map((itemCity: any, index: any) => {
                  return <InternationalPackageCIty isRequiredField={isRequiredField} index={index} item={itemCity} pkgCityHOtelmeal={pkgCityHotelMealPlans} />
                })
            }
            <PackageTransferAndlocation cabtransfer={cabtransfer} droplocation={droplocation} pickuplocation={pickuplocation} isRequiredField={isRequiredField} packageDetails={packageDetails} transportRateList={transportRateList} pickupAndDropList={pickupAndDropList} onChangeHandller={onChangeHandller} />
            {/* {renderSection3()} */}
            {renderSection4()}
          </> : activeTab === "Travel Plan" ? <>{renderTravelPlan()}</> : <>
            {InclusionSection(true)}
            {packageDetails?.inclusionsList?.filter((incl: any, i: number) => !incl.is_include)?.length > 0 && InclusionSection(false)}
          </>
          }
          {pdfPreview()}
          {/* <SectionDateRange /> */}
          {/* {renderSection5()} */}
          {renderSection6()}
          {/* {renderSection7()} */}
          {/* {renderSection8()} */}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>
    </div>
  );
};

export default function ListingStayDetailPage() {
  const packageDetails = useSelector((state: any) => state.selectOptionFilterReducer.packageDetails)
  return (
    <DetailPagetLayout imageList={packageDetails?.imageList}>
      <Helmet>
        <title>{applicationName} || Package Quotation</title>
      </Helmet>
      <PackageQuotation />
    </DetailPagetLayout>
  );
}
