import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";

//Forms
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";

//Authentication OR Social 
import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

//Images
import facebookSvg from "images/Facebook.svg";
import googleSvg from "images/Google.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SignUpImage from "images/SignIn_3.png";
import NcImage from "shared/NcImage/NcImage";

//Common Service
import { faceBookAppId, googleClientId } from "../../../services/services/common/const";
import { setSession } from "../../../services/services/common/session";

//Business Logic Services
import { signup, loginWithSocial, saveCompany} from "../../../services/services/User/userService";

//Password input
import UserPasswordInput from "../../../components/User/UserPasswordInput"


export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {

  const [value, setValue] = useState(
        {          
          firstname: "",
          lastname: "",
          password: "",
          confirmpassword: "",
          companyname: "",
          contactnumber: null,
          email: "",
          gstno: "",
          conditioncheck: false,
        }
        );
  const [passwordShow, setPasswordShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { firstname, lastname } = value;
  const navigate = useNavigate();

 /**This function for new user Signup */
    const signupUser = async (user: any) => {
    // alert("BB");
      setIsLoading(true);
      try {
        const response = await signup({
          FirstName: user.firstname,
          LastName: user.lastname,
          Phone: user.contactnumber.toString(),
          UserName: user.email,
          Password: user.password,
          ConfirmPassword: user.confirmpassword,
          Role: "Client",
          Email: user.email,
          Operation: "ADD",
          Social_media_type: "APPLICATION",
          FromApplication: "Client_module",
          Row_created_by: `${user.firstname} ${user.lastname}`,
          Row_altered_by: `${user.firstname} ${user.lastname}`,
        });
        if (response.status === "SUCCESS") {
          /**This is for save company details */
          const responseCompany = await saveCompany({
            Company_name: user.companyname,
            Company_gst_no: user.gstno,
            Operation: "ADD",
            Is_active: true,
            Row_created_by: user.email,
            Row_altered_by: user.email,
            AspNetUserId: response.aspNetUserId,
          });
          if (responseCompany.status === "SUCCESS") {
            navigate("/signup-success");
            setErrorMessage("");
          }
        } else {
          setErrorMessage(response.message);
        }
      } catch (error: any) {
        setErrorMessage(error);
      } finally {
        setIsLoading(false);
      }
    };

  /**This variable for contact number validation */
  const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,
  
      initialValues: {
        firstname: "",
        lastname: "",
        password: "",
        confirmpassword: "",
        companyname: "",
        contactnumber: null,
        email: "",
        gstno: "",
        conditioncheck: false,
      },
      validationSchema: Yup.object({
        firstname: Yup.string().max(20).min(3).required("First Name is required"),
        lastname: Yup.string().max(20).min(3).required("Last Name is required"),      
        password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("Password required"),
      confirmpassword: Yup.string()
         .required("Password Confirm required")
        .oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password are not matched"
        ),
        email: Yup.string().email().required("Email Id is required"),
        companyname: Yup.string().required("Company Name is required"),
      contactnumber: Yup.string()
        .matches(phoneRegExp, "Contact Number is not valid")
        .required("Contact Number is not valid"),

      }),
      onSubmit: (values: any) => {
       // alert('AA');
        signupUser(values);
      },
    });
  

  /**login with facebook */
  const responseFacebook = async (response: any) => {
    try {
    
      const { email, first_name, last_name, name } = response;
      const responseLogin = await loginWithSocial({
        Email: email,
        Name: name,
        Email_verified: true /**This type manualy because not get yet resoponsefacebook */,
        Role: "Client",
        FirstName: first_name,
        LastName: last_name,
        Operation: "ADD",
        Social_media_type: "FACEBOOK",
        FromApplication: "Client_module",
      });
      if (responseLogin.status === "SUCCESS") {
        const responseCompany = await saveCompany({
          Company_name: "",
          Company_gst_no: "",
          Operation: "ADD",
          Is_active: true,
          Row_created_by: responseLogin.email,
          Row_altered_by: responseLogin.email,
          AspNetUserId: responseLogin.userKey,
        });
        if (responseCompany.status === "SUCCESS") {
          setSession("userDetails", responseLogin);
          navigate("/");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loginWithgoogle = useGoogleLogin({
    onSuccess: async (codeResponse: any) => {
      try {
        const response = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
            },
          }
        );
        const {
          sub,
          name,
          given_name,
          family_name,
          picture,
          email,
          email_verified,
          local,
        } = response.data;
        const responseLogin = await loginWithSocial({
          Email: email,
          Name: name,
          Email_verified: email_verified,
          Role: "Client",
          FirstName: given_name,
          LastName: family_name,
          Operation: "ADD",
          Social_media_type: "GOOGLE",
          FromApplication: "Client_module",
        });

        if (responseLogin.status === "SUCCESS") {
          const responseCompany = await saveCompany({
            Company_name: "",
            Company_gst_no: "",
            Operation: "ADD",
            Is_active: true,
            Row_created_by: responseLogin.email,
            Row_altered_by: responseLogin.email,
            AspNetUserId: responseLogin.userKey,
          });

          if (responseCompany.status === "SUCCESS") {
            setSession("userDetails", responseLogin);
            navigate("/");
          }
        }
      } catch (error) {}
    },
  });

  const handleLogin = async (credentialResponsez: any) => {
}

  return (

    
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Signup || TravelNinjaz</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-2 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {/* Signup */}
        </h2>
       

        <div className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`} data-nc-id="SectionSubscribe2" >
          <div className="flex-grow">
            <NcImage src={SignUpImage} />
          </div>
          <div className="flex-shrink-0 mb-10 lg:mb-0 lg:ml-10 lg:w-3/5 sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
            
              <h2 className="font-semibold text-4xl">Signup 🎉</h2>     

               {/* FORM */}

              <form
                 className="grid grid-cols-1 gap-6"
                  action="#"
                  onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                  }}
                >
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
                
                  <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                   First Name
                  </span>
                  <Input                  
                 
                    id="firstname"                    
                    name="firstname"
                    type={"text"}
                    className="mt-1"
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    placeholder="Enter First Name"
                    required={true}                    
                    value={validation.values.firstname}

                  />

                 {
                      validation.errors.firstname ? (                       
                        <p className="validationError" style={{ color: "red" }}>
                        {validation.errors.firstname.toLocaleString()}
                      </p>
                 ) : null}
               

                  </label>
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                    Last Name
                    </span>
                      <Input
                        id="lastname"
                        name="lastname"
                        type={"text"}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        placeholder="Enter Last Name"
                        required={true}
                        value={validation.values.lastname}
                      />

                        {
                          validation.errors.lastname ? (
                            <p className="validationError" style={{ color: "red" }}>
                             {validation.errors.lastname.toLocaleString()}
                           </p>
                          ) : null}
                  </label>                    
             
                 
                      <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                        Company Name
                        </span>
                        <Input    
                          id="companyname"
                          type={"text"}
                          name="companyname"
                          onBlur={validation.handleBlur}
                          onChange={validation.handleChange}
                          placeholder="Enter Company Name"
                          required={true}
                          value={validation.values.companyname}
                        />

                        {
                              validation.errors.companyname ? (                       
                                <p className="validationError" style={{ color: "red" }}>
                                {validation.errors.companyname.toLocaleString()}
                              </p>
                        ) : null}                     

                      </label>
                      <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                          GST number
                        </span>
                          <Input
                               id="gstno"
                               type={"text"}
                               name="gstno"
                               onBlur={validation.handleBlur}
                               onChange={validation.handleChange}
                               placeholder="Enter GST number"
                               required={false}
                               value={validation.values.gstno}
                          />

                            {
                              validation.errors.gstno ? (
                                <p className="validationError" style={{ color: "red" }}>
                                {validation.errors.gstno.toLocaleString()}
                              </p>
                              ) : null}
                      </label> 
                            

              
                      <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                        Email
                        </span>
                        <Input    
                            className={
                              validation.errors.email
                                ? "border border-danger"
                                : ""
                            }
                            id="email"
                            type={"email"}
                            name="email"
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            placeholder="Enter Email Id"
                            required={true}
                            value={validation.values.email}
                        />

                        {
                              validation.errors.email ? (                       
                                <p className="validationError" style={{ color: "red" }}>
                                {validation.errors.email.toLocaleString()}
                              </p>
                        ) : null}                     

                      </label>
                     
                      <label className="block">
                        <span className="text-neutral-800 dark:text-neutral-200">
                            Contact Number
                        </span>
                          <Input
                              id="contactnumber"
                              type={"number"}
                              name="contactnumber"
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              placeholder="Enter Contact Number"
                              required={true}
                              value={validation.values.contactnumber} 
                          />

                            {
                              validation.errors.contactnumber ? (
                                <p className="validationError" style={{ color: "red" }}>
                                {validation.errors.contactnumber.toLocaleString()}
                              </p>
                              ) : null}
                      </label>               
               
                      <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                             Password
                            </span>

                          <UserPasswordInput
                          id="password"
                          name="password"
                          type={passwordShow ? "text" : "password"}
                          className="mt-1" 
                          placeholder="Password"
                          required={true}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password}
                        />

                         {
                        validation.errors.password ? (                       
                          <p className="validationError" style={{ color: "red" }}>
                          {validation.errors.password.toLocaleString()}
                        </p>
                        ) : null}
                        
                        </label>     

                        <label className="block">

                        <span className="text-neutral-800 dark:text-neutral-200">
                             Confirm Password
                            </span>

                    <UserPasswordInput
                     id="confirmpassword"
                     name="confirmpassword"
                     type={passwordShow ? "text" : "password"}
                     className="mt-1" 
                     placeholder="Confirm Password"
                     required={true}
                     onChange={validation.handleChange}
                     onBlur={validation.handleBlur}
                     value={validation.values.confirmpassword}
                 />

                    {
                      validation.errors.confirmpassword ? (
                        <p className="validationError" style={{ color: "red" }}>
                        {validation.errors.confirmpassword.toLocaleString()}
                      </p>
                      ) : null}
                   </label>    
            
              </div>
                
              

                <ButtonPrimary type="submit" loading={isLoading} >Signup</ButtonPrimary>
              </form>

              {errorMessage !== undefined ? (
                    <p className="validationError" style={{ color: "red" }}>
                    {errorMessage}
                    </p>
                  ) : (
                    ""
                  )}
                  
               {/* OR */}
              <div className="relative text-center">
                <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                  OR
                </span>
                <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
              </div>        
              <div className="grid gap-3 grid-cols-1 md:grid-cols-2 gap-8 md:gap-5">
              <label className="block">
                          <FacebookLogin
                            appId={faceBookAppId}
                            autoLoad={false}
                            fields="name,email,picture,first_name, last_name"
                            callback={responseFacebook}
                            cssClass="my-facebook-button-class"
                            icon="fa-facebook facebookIconStyle"
                            render={(renderProps) => (
                              
                                <a
                                    key={"1"}
                                    href=""
                                    onClick={renderProps.onClick}
                                    className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                                  >
                                    <img
                                      className="flex-shrink-0"
                                      src={facebookSvg}
                                      alt={"Continue with Facebook"}
                                    />
                                    <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                                      {"Continue with Facebook"}
                                    </h3>
                                  </a>                          
                            )}
                          />                          
                    </label>
                      <label>
                           <GoogleOAuthProvider  clientId={googleClientId}>
                           <a
                                  key={"2"}
                                  href={"#"}
                                  className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                                  onClick={() => loginWithgoogle()}
                              >
                                  <img
                                    className="flex-shrink-0"
                                    src={googleSvg}
                                    alt={"Continue with Google"}
                                  />
                                  <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                                    {"Continue with Google"}
                                  </h3>
                                </a>                          
                            </GoogleOAuthProvider>
                        </label>


          {/* {
              <a
                key={"1"}
                href={"#"}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={facebookSvg}
                  alt={"Continue with Facebook"}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {"Continue with Facebook"}
                </h3>
              </a>
           } */}

            {/* {
              <a
                key={"2"}
                href={"#"}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                onClick={() => loginWithgoogle()}
             >
                <img
                  className="flex-shrink-0"
                  src={googleSvg}
                  alt={"Continue with Google"}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {"Continue with Google"}
                </h3>
              </a>
           } */}


          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
               Already have an account?{` `}
             <Link to="/login" className="underline">Sign in</Link>
        </span>

            </div>

    
    </div>
      </div>
    </div>
  );
};

export default PageSignUp;
