import React, { FC, useState, useEffect } from "react";
import MainNav1 from "./MainNav1";
import MainNav2 from "./MainNav2";
import MainNav3 from "./MainNav3";

export interface HeaderProps {
  headerType?: "Header1" | "Header2" | "Header3";
  navType?: "MainNav1" | "MainNav2" | "MainNav3";
  className?: string;
}

const Header: FC<HeaderProps> = ({headerType = "Header3", navType = "MainNav1", className = "" }) => {

  const renderNav = () => {
    switch (navType) {
      case "MainNav1":
        return <MainNav1 />;
      case "MainNav2":
        return <MainNav2 />;
      case "MainNav3":
          return <MainNav3 />;
      default:
        return <MainNav1 />;
    }
  };

  const [navClass, setnavClass] = useState("nc-header-bg-transparent");


  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
});

const scrollNavigation = () => {
  var scrollup = document.documentElement.scrollTop;
  if (scrollup > 80) {
   // alert('A');
     setnavClass("nc-header-bg-dark");
  } else {
   // alert('B');
      //setnavClass("nc-header-bg-transparent");
      setnavClass("nc-header-bg-transparent");
  }
}

// Background image change 2 
// if (headerType = "Header1") {
//   return (
    
//     <div
//       className={`nc-Header sticky top-0 w-full left-0 right-0 z-40 ${navClass} ${className}`}
//     >
//       {renderNav()}
//     </div>
//   );
// }

// else if (headerType = "Header3") {
//   return (

    
//     <div
//       className={`nc-Header sticky top-0 w-full left-0 right-0 z-40 nc-header-bg`}
//     >
//       {renderNav()}
//     </div>
//   );
// };
// }

// Background image change 2  ends 

  return (
    
    // <div
    //   className={`nc-Header sticky top-0 w-full left-0 right-0 z-40 ${navClass} ${className}`}
    // >
    //   {renderNav()}
    // </div>

    <div
      className={`nc-Header sticky top-0 w-full left-0 right-0 z-40  nc-header-bg ${className}`}
    >
      {renderNav()}
    </div>

  );
}
export default Header;
