import React, { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import facebookSvg from "images/Facebook.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";

//Images
import SignInImage from "images/SignIn_1.png";
import NcImage from "shared/NcImage/NcImage";

//Forms
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

//Common Service
import { setSession } from "../../../services/services/common/session";
import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { faceBookAppId, googleClientId } from "../../../services/services/common/const";

import {  login,  loginWithSocial,  saveCompany } from "../../../services/services/User/userService";

//Password input
import UserPasswordInput from "../../../components/User/UserPasswordInput"


export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {

  const [value, setValue] = useState({ username: "", password: "" });
  const [passwordShow, setPasswordShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { username, password } = value;
  const navigate = useNavigate();

    /**This function for user login */
    const loginUser = async (values: any) => {
      setIsLoading(true);
      setErrorMessage(undefined);
      try {
        // Check if User is valid from WebAPI Database
        const user = await login({
          Username: values?.username,
          Password: values?.password,
          Social_media_type: "APPLICATION",
        });
        if (user?.status === "SUCCESS") {
          var promise = setSession("userDetails", user);
          // localStorage.setItem("userDetails", user);
          promise.then(function () {
            navigate("/home");
          });
  
          setErrorMessage(undefined);
        } else {
          setErrorMessage(user.message);
        }
      } catch (error) {
        //setErrorMessage(error);
      } finally {
        setIsLoading(false);
      }
    };

    const validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,
  
      initialValues: {
        username: username,
        password: password,
      },
      validationSchema: Yup.object({
        username: Yup.string().required("Please Enter Your Username"),
        password: Yup.string().required("Please Enter Your Password"),
      }),
      onSubmit: (values: any) => {
        loginUser(values);
      },
    });
  

  /**login with facebook */
  const responseFacebook = async (response: any) => {
    try {
      const { email, first_name, last_name, name } = response;
      const responseLogin = await loginWithSocial({
        Email: email,
        Name: name,
        Email_verified: true /**This type manualy because not get yet resoponsefacebook */,
        Role: "Client",
        FirstName: first_name,
        LastName: last_name,
        Operation: "ADD",
        Social_media_type: "FACEBOOK",
        FromApplication: "Client_module",
      });
      if (responseLogin.status === "SUCCESS") {
        const responseCompany = await saveCompany({
          Company_name: "",
          Company_gst_no: "",
          Operation: "ADD",
          Is_active: true,
          Row_created_by: responseLogin.email,
          Row_altered_by: responseLogin.email,
          AspNetUserId: responseLogin.userKey,
        });
        if (responseCompany.status === "SUCCESS") {
          setSession("userDetails", responseLogin);
          navigate("/");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loginWithgoogle = useGoogleLogin({
    onSuccess: async (codeResponse: any) => {
      try {
        const response = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
            },
          }
        );
        const {
          sub,
          name,
          given_name,
          family_name,
          picture,
          email,
          email_verified,
          local,
        } = response.data;
        const responseLogin = await loginWithSocial({
          Email: email,
          Name: name,
          Email_verified: email_verified,
          Role: "Client",
          FirstName: given_name,
          LastName: family_name,
          Operation: "ADD",
          Social_media_type: "GOOGLE",
          FromApplication: "Client_module",
        });

        if (responseLogin.status === "SUCCESS") {
          const responseCompany = await saveCompany({
            Company_name: "",
            Company_gst_no: "",
            Operation: "ADD",
            Is_active: true,
            Row_created_by: responseLogin.email,
            Row_altered_by: responseLogin.email,
            AspNetUserId: responseLogin.userKey,
          });

          if (responseCompany.status === "SUCCESS") {
            setSession("userDetails", responseLogin);
            navigate("/");
          }
        }
      } catch (error) {}
    },
  });

  return (

    
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || TravelNinjaz</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-2 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {/* Login */}
        </h2>
       

        <div className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`} data-nc-id="SectionSubscribe2" >
          <div className="flex-grow">
            <NcImage src={SignInImage} />
          </div> 
          <div className="flex-shrink-0 mb-10 lg:mb-0 lg:ml-10 lg:w-2/5 sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
            
              <h2 className="font-semibold text-4xl">SignIn 🎉</h2>
              {/* <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                Read and share new perspectives on just about any topic. Everyone’s
                welcome.
              </span> */}
           
              {/* FORM */}
            
              <form
                 className="grid grid-cols-1 gap-6 relative"
                  action="#"
                  onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                  }}
                >

                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                   Username
                  </span>
                  <Input
                    id="username"
                    type="email"
                    placeholder=""
                    className="mt-1"
                    required={true}
                    onChange={validation.handleChange}
                    value={validation.values.username}
                    onBlur={validation.handleBlur}
                    autoComplete={"false"} 
                    // onChange={onChange}
                  />

                 {validation.touched.username &&
                      validation.errors.username ? (                       
                      <p className="validationError" style={{ color: "red" }}>
                        {validation.errors.username.toLocaleString()}
                      </p>
                 ) : null}

                   {/* {!username ? (                       
                      <p className="validationError" style={{ color: "red" }}>
                       {"Please Enter Your Username"}
                      </p>
                 ) : null} */}

                </label>

                <div className="col-lg-12 position-relative">
                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Password
                    <Link to="/forgotpassword" className="text-sm">
                      Forgot password?
                    </Link>
                  </span>                 
                     
                    
              
           
                <UserPasswordInput
                     id="password"
                     name="password"
                     type={passwordShow ? "text" : "password"}
                     className="mt-1" 
                     placeholder="Password"
                     required={true}
                     onChange={validation.handleChange}
                     onBlur={validation.handleBlur}
                     value={validation.values.password}
                    // autoComplete={"false"} 
                 />
                
                {validation.touched.password &&
                          validation.errors.password ? (
                            <p className="validationError" style={{ color: "red" }}>
                                {validation.errors.password.toLocaleString()}
                          </p>
                          ) : null
                      }    
                </label>
                </div>
                {errorMessage !== undefined ? (
                        <p className="validationError" style={{ color: "red" }}>
                        {errorMessage}
                    </p>
                        ) : (
                          ""
                        )}
                <ButtonPrimary type="submit" loading={isLoading} >Signin</ButtonPrimary>

              </form>
             
               {/* OR */}
              <div className="relative text-center">
                <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                  OR
                </span>
                <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
              </div>
              <div className="grid gap-3">
                    <div>
                          <FacebookLogin
                            appId={faceBookAppId}
                            autoLoad={false}
                            fields="name,email,picture,first_name, last_name"
                            callback={responseFacebook}
                            cssClass="my-facebook-button-class"
                            icon="fa-facebook facebookIconStyle"
                            render={(renderProps) => (
                              
                                <a
                                    key={"1"}
                                    href=""
                                    onClick={renderProps.onClick}
                                    className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                                  >
                                    <img
                                      className="flex-shrink-0"
                                      src={facebookSvg}
                                      alt={"Continue with Facebook"}
                                    />
                                    <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                                      {"Continue with Facebook"}
                                    </h3>
                                  </a>                          
                            )}
                          />                          
                      </div>
                      <div>
                           <GoogleOAuthProvider  clientId={googleClientId}>
                           <a
                                  key={"2"}
                                  href={"#"}
                                  className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                                  onClick={() => loginWithgoogle()}
                              >
                                  <img
                                    className="flex-shrink-0"
                                    src={googleSvg}
                                    alt={"Continue with Google"}
                                  />
                                  <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                                    {"Continue with Google"}
                                  </h3>
                                </a>                          
                            </GoogleOAuthProvider>
                        </div>         


          </div>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup" className="underline">Create an account</Link>
          </span>

            </div>

    
    </div>
      </div>
    </div>
  );
};

export default PageLogin;
